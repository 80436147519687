import { Col, Row } from "react-bootstrap"
import { ArrowLeft } from "react-bootstrap-icons"
import { useNavigate } from "react-router-dom"

export const TitleWithBackButton = ({ title }) => {

    const navigate = useNavigate();

    return (
    <Row className="border-0 p-3 bg-dark-subtle border-bottom border-white mb-3">
        <Col xs={2} className="p-0">
            <ArrowLeft
                size={24}
                onClick={() => navigate(-1)}
                style={{cursor: 'pointer'}}
            />
        </Col>
        <Col 
            xs={8}
            className="text-center d-flex align-items-center justify-content-center"
        >
            <h5 className="m-0">{title}</h5>
        </Col>
        <Col xs={2}>
            
        </Col>
    </Row>
    )
}