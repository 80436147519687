import { Col, Container, Row, Stack } from "react-bootstrap"
import { TitleWithBackButton } from "../../../components/title-with-back-button/titleWithBackButton"
import { useRecoilValue } from "recoil";
import { userState } from "../../../utils/atom";
import { getRequest } from "../../../utils/apiRequest";
import { useQuery } from "@tanstack/react-query";
import { Loader } from "../../../components/loader";
import { UserAddress } from "../../admin/users/user-detail-components/userAddress";
import { UserContact } from "../../admin/users/user-detail-components/userContact";
import { UserChildren } from "../../admin/users/user-detail-components/userChildren";
import { UserUnpaidEnrollment } from "../../admin/users/user-detail-components/userUnpaidEnrollment";
import { UserBroadcastOptin } from "../../admin/users/user-detail-components/userBroadcastOptin";

export const UserProfile = () => {

    const { token } = useRecoilValue(userState);
    const userQuery = useQuery({
        queryKey: ['user-profile-query'],
        queryFn: () => getRequest(`/auth/getuser`, token)
    });
    const { data } = userQuery;

    return (
        <Loader isActive={userQuery.isLoading || userQuery.isFetching}>
            {data &&
                <Container fluid>
                    <TitleWithBackButton title={`${data.firstName} ${data.lastName}`} />
                    <Container className="pb-3">
                        <Row>
                            <Col xs={12} md={6} className="mt-3">
                                <Stack gap={3}>
                                    <UserAddress 
                                        user={data}
                                        token={token}
                                    />

                                    <UserBroadcastOptin 
                                        user={data}
                                        token={token}
                                    />

                                    <UserChildren 
                                        user={data}
                                        token={token}
                                        isAdmin={false}
                                    />
                                </Stack>
                            </Col>

                            <Col xs={12} md={6} className="mt-3">
                                <Stack gap={3}>
                                    <UserContact 
                                        user={data}
                                        token={token}
                                    />
                                    <UserUnpaidEnrollment 
                                        user={data}
                                        token={token}
                                    />
                                </Stack>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            }
        </Loader>
    )
}