import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap"
import { ArrowLeft, PeopleFill } from "react-bootstrap-icons"
import { useNavigate, useOutletContext } from "react-router-dom"
import { useRecoilValue } from "recoil";
import { userState } from "../../utils/atom";
import { useQuery } from "@tanstack/react-query";
import { getRequest, postRequest } from "../../utils/apiRequest";
import { v4 } from 'uuid';

export const AddChildren = () => {

    const { token } = useRecoilValue(userState);
    const [showToast, setShowToast] = useOutletContext();
    const navigate = useNavigate();

    const [childData, setChildData] = useState({
        id: v4(),
        firstName: '',
        lastName: '',
        birthdate: '',
        performanceLevel: 0,
        note: '',
        parentId: ''
    });

    const userQuery = useQuery({
        queryKey: ['userStateQuery'],
        queryFn: () => getRequest('/auth/getuser', token),
    });
    const { data } = userQuery;

    useEffect(() => {
        if (data) {
            setChildData((prev) => {
                return ({
                    ...prev,
                    parentId: data.id
                })
            })
        }
    }, [data])
    
    const handleChange = (event) => {
        const { name, value } = event.target;
        const valueToStore = name === "performanceLevel" ? Number(value) : value;
        setChildData(prevState => ({
            ...prevState,
            [name]: valueToStore
        }));
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setChildData((prevState) => ({...prevState, performanceLevel: Number(prevState.performanceLevel)}));
        const response = await postRequest('/auth/new-child', token, childData);
        if (response.error) {
            setShowToast({
                toastStatus: true, 
                toastMessage: response.error,
                toastAppearance: 'danger'
            });
        }
        else {
            setShowToast({
                toastStatus: true,
                toastMessage: 'Teilnehmer erfolgreich gespeichert. 🥳',
                toastAppearance: 'success'
            })
            navigate('/');
        }
    }

    return (
        <Container fluid className="p-0">
            <Row className="border-0 p-3 bg-dark-subtle border-bottom border-white mb-3">
                <Col xs={2}>
                    <ArrowLeft 
                        size={20}
                        onClick={() => navigate('/')}
                        style={{cursor: 'pointer'}}
                    />
                </Col>
                <Col 
                    xs={8}
                    className="text-center d-flex align-items-center justify-content-center"
                >
                    <h5 className="m-0">Teilnehmer hinzufügen</h5>
                </Col>
                <Col xs={2}></Col>
            </Row>

            <Container>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col>
                            <Card className="mt-3">
                                <Card.Header>Angaben zum Teilnehmer</Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col xs={12} md={6} className="mb-3">
                                            <Form.Label>Vorname</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={childData.firstName}
                                                onChange={handleChange}
                                                name="firstName"
                                                required
                                            >
                                            </Form.Control>
                                        </Col>

                                        <Col xs={12} md={6} className="mb-3">
                                            <Form.Label>Nachname</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={childData.lastName}
                                                onChange={handleChange}
                                                name="lastName"
                                                required
                                            >
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col xs={12}>
                                            <Form.Label>Geburtsdatum</Form.Label>
                                            <Form.Control
                                                type="date"
                                                value={childData.birthdate}
                                                onChange={handleChange}
                                                name="birthdate"
                                                required
                                            >
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                    
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col>                      
                            <Card className="mt-3">
                                <Card.Header>Angaben zum Leistungsstand</Card.Header>
                                <Card.Body>
                                    <Row className="mb-3">
                                        <Col xs={12}>
                                            <Form.Label>Leistungsstand</Form.Label>
                                            <Form.Select
                                                value={childData.performanceLevel}
                                                onChange={handleChange}
                                                name="performanceLevel"
                                                required
                                            >
                                                <option value={0}>Babyschwimmen</option>
                                                <option value={1}>Wassergewöhnung</option>
                                                <option value={2}>Anfänger</option>
                                                <option value={3}>Fortgeschritten</option>
                                            </Form.Select>
                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col xs={12}>
                                            <Form.Label>Sonstige Hinweise</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                style={{height: '100px'}}
                                                value={childData.note}
                                                onChange={handleChange}
                                                name="note"
                                            ></Form.Control>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col xs={12}>
                            <Form.Control 
                                style={{backgroundColor: '#0A5ED7', border: 'none'}} 
                                type="submit"
                                value={'Speichern'}
                            >
                            </Form.Control>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </Container>
    )
}