import { useQuery } from "@tanstack/react-query"
import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap"
import { getRequest, postRequest } from "../../utils/apiRequest"
import { useRecoilValue } from "recoil";
import { userState } from "../../utils/atom";
import { Loader } from "../loader";
import { useNavigate, useOutletContext } from "react-router-dom";
import { formatDateTimeToDDMMYY, formatDateTimeToHHMM } from "../../utils/formatDateTime";
import '../../styles/next-schedules/next-schedules.scss';
import { useState } from "react";

export const YourNextSchedules = () => {

    const { token } = useRecoilValue(userState);
    const [showDeleteEnrollmentModal, setShowDeleteEnrollmentModal] = useState(false);
    const [childIndex, setChildIndex] = useState();
    const [enrollmentIndex, setEnrollmentIndex] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [showToast, setShowToast] = useOutletContext();
    const navigate = useNavigate();

    const schedulesCoursesQuery = useQuery({
        queryKey: ['nextschedules'],
        queryFn: () => getRequest('/auth/getschedules', token)
    });
    const { data } = schedulesCoursesQuery;

    const handleDeleteEnrollment = async (id) => {
        setIsLoading(true);
        const response = await postRequest('/auth/delete-enrollment', token, {id: id});
        if (response.error) {
            setShowToast({
                toastStatus: true,
                toastMessage: response.error,
                toastAppearance: 'danger'
            });
        }
        else {
            setShowToast({
                toastStatus: true,
                toastMessage: 'Deine Kursabmeldung war erfolgreich. 🥳',
                toastAppearance: 'success'
            });
            schedulesCoursesQuery.refetch();  
        }
        setShowDeleteEnrollmentModal(false);
        setIsLoading(false);
    }

    return (
        <Loader isActive={schedulesCoursesQuery.isFetching || schedulesCoursesQuery.isLoading || isLoading}>
            {data &&
                <Container className="border-bottom p-3">
                    <Row>
                        <Col>
                            <h5>Deine nächsten Termine</h5>
                        </Col>
                    </Row>

                    {data[0] && data[0].enrollments.length > 0 
                    ?
                        (data.map((child, childIndex) => {
                            return (
                                <Row key={childIndex}>
                                    <Col className="mt-3 mb-3">
                                        <Card>
                                            <Card.Header>
                                                {child.firstName}
                                            </Card.Header>
                                            <Card.Body 
                                                className="p-0"
                                                style={{overflow: 'hidden'}}
                                            >
                                                {child.enrollments.map((enrollment, enrollmentIndex) => {
                                                    return (
                                                        <Row 
                                                            className="hoverable-title p-3 rounded schedule-row"
                                                            key={enrollment.id}
                                                            onClick={() => navigate(`/course/${enrollment.course.id}`)}
                                                        >
                                                            <Col xs={9} md={6} className="d-flex flex-column justify-content-center">
                                                                <p className="m-0">{enrollment.course.title}</p>
                                                                <p className="m-0">{formatDateTimeToDDMMYY(enrollment.course.date)}, {formatDateTimeToHHMM(enrollment.course.date)} Uhr</p>
                                                            </Col>

                                                            <Col xs={3} md={6} className="d-flex justify-content-end">
                                                                <Button 
                                                                    className="btn-danger ps-3 pe-3"
                                                                    style={{maxHeight: '50px'}}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setChildIndex(childIndex);
                                                                        setEnrollmentIndex(enrollmentIndex);
                                                                        setShowDeleteEnrollmentModal(true);
                                                                    }}
                                                                >
                                                                        Abmelden
                                                                    </Button>
                                                            </Col>
                                                        </Row>
                                                    )
                                                })}
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            )
                        }))
                    :   
                        
                        <Row>
                            <Col className="mt-3">
                                Deine Kinder sind noch zu keinem unserer Kurse angemeldet.
                            </Col>
                        </Row>
                        
                    }
                </Container>
            }

            {data && data.length > 0 && data[childIndex] && data[childIndex].enrollments && data[childIndex].enrollments[enrollmentIndex] && (
                <Modal 
                    centered
                    show={showDeleteEnrollmentModal}
                    onHide={() => setShowDeleteEnrollmentModal(false)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Vom Kurs abmelden?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12}>
                                {`
                                    Du bist dabei ${data[childIndex].firstName} vom Kurs ${data[childIndex].enrollments[enrollmentIndex].course.title} am
                                    ${formatDateTimeToDDMMYY(data[childIndex].enrollments[enrollmentIndex].course.date)} abzumelden. Bitte bedenke, dass
                                    eine Abmeldung kostenfrei nur bis zum Vortag des Kurses um 18:00 Uhr möglich ist und dir anderenfalls die vollen Kurskosten 
                                    in Rechnung gestellt werden. Leistungskurse, welche in einer monatlichen Pauschale enthalten sind, sind von dieser Regelung
                                    nicht betroffen.
                                `}
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => setShowDeleteEnrollmentModal(false)}>Abbrechen</Button>
                        <Button
                            className="btn-danger"
                            onClick={() => handleDeleteEnrollment(data[childIndex].enrollments[enrollmentIndex].id)}
                        >
                            Vom Kurs abmelden
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </Loader>
    )
}