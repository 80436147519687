import { Col, Container, Row } from "react-bootstrap"
import { TitleWithBackButton } from "../components/title-with-back-button/titleWithBackButton"

export const Imprint = () => {
    return (
        <Container fluid>
            <TitleWithBackButton title={"Impressum"} />
            <Container className="d-flex justify-content-center mt-3">
                <Row className="text-center">
                    <Col xs={12}>
                        René Schmidt / Kiddiefy
                    </Col>
                    <Col xs={12}>
                        Ovelgönner Weg 31
                    </Col>
                    <Col xs={12}>
                        21335 Lüneburg
                    </Col>
                    <Col xs={12}>
                        E-Mail: info@kiddiefy.de
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}