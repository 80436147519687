import { Button, Card, Col, Container, Form, Row, Toast } from "react-bootstrap"
import { Loader } from "../../../components/loader"
import { useQuery } from "@tanstack/react-query";
import { getRequest, postRequest } from "../../../utils/apiRequest";
import { useRecoilValue } from "recoil";
import { userState } from "../../../utils/atom";
import { useEffect, useState } from "react";
import { formatDateAndTimeToISO, formatDateTimeToHHMM, formatDateTimeToYYYYMMDD } from "../../../utils/formatDateTime";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { TitleWithBackButton } from "../../../components/title-with-back-button/titleWithBackButton.jsx";


export const EditCourse = () => {

    const { token } = useRecoilValue(userState);
    const { id } = useParams();
    const [showToast, setShowToast] = useOutletContext();

    const [courseData, setCourseData] = useState({
        id: '',
        title: '',
        date: '',
        locationName: '',
        locationPostalCode: '',
        locationCity: '',
        locationStreet: '',
        locationStreetNumber: '',
        cost: '',
        maxParticipants: 0,
        approved: false,
        courseTeacher: '',
        courseInfo: '',
        isPerformanceCourse: false,
        performanceLevel: 0,
        courseDate: '', // Date & Time are string that will later be converted to DateTimeObject together
        courseTime: '',
    });

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
 
    const courseQuery = useQuery({
        queryKey: ['course-to-edit'],
        queryFn: () => getRequest(`/auth/edit-course/${id}`, token)
    });
    const { data } = courseQuery;

    const handleChange = (e) => {
        setCourseData((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        });
    }

    const handleSaveCourse = async () => {
        if (Object.entries(courseData).some(([key, value]) => {
            if (key === 'performanceLevel' || key === 'courseInfo' || key === 'id') {
                return false;
            }
            return value === '' || value === 0;
        })) {
            setSuccess('');
            setError('Alle Felder müssen ausgefüllt sein, um den Kurs zu updaten.');
            return;
        }
        setIsLoading(true);
        const data = {
            ...courseData,
            date: formatDateAndTimeToISO(courseData.courseDate, courseData.courseTime),
            updatedAt: new Date(),
            performanceLevel: Number(courseData.performanceLevel),
            maxParticipants: Number(courseData.maxParticipants)
        };
        // Deleting related fields from course object, because we are not updating them here.
        delete data.participants;
        delete data.createdById;
        delete data.waitingRooms;
        delete data.courseDate;
        delete data.courseTime;
        const response = await postRequest('/auth/update-course', token, data);
        if (response.error) {
            setError(response.error);
            setSuccess('');
            setIsLoading(false);
        }
        else {
            setShowToast({
                toastStatus: true, 
                toastMessage: 'Kurs erfolgreich aktualisiert. 🥳',
                toastAppearance: 'success'
            });
            navigate('/');
        }     
    }

    const handleDeleteCourse = async (id) => {
        setIsLoading(true);
        const response = await postRequest('/auth/delete-course', token, {id: id});
        if (response.error) {
            setError(response.error);
            setSuccess('');
            setIsLoading(false);
        }
        else {
            setShowToast({
                toastStatus: true, 
                toastMessage: 'Kurs erfolgreich gelöscht.',
                toastAppearance: 'success'
            });
            navigate('/');
            setIsLoading(false);
        }     
    }

    useEffect(() => {
        if (data) {
            setCourseData({
                ...data,
                courseDate: formatDateTimeToYYYYMMDD(data.date),
                courseTime: formatDateTimeToHHMM(data.date)
            });
        }
    }, [data]);

    return (
        <Loader isActive={courseQuery.isFetching || courseQuery.isLoading || isLoading}>
            {data &&
                <Container fluid>
                    <TitleWithBackButton title={'Kurs bearbeiten'}/>

                    <Container>
                        <Row className="mt-3 mb-3">
                            <Col className="mb-3">
                                <Form>
                                    <Card className="mb-3">
                                        <Card.Header>Allgemein</Card.Header>
                                        <Card.Body>
                                            <Form.Group className="mb-3">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Kurstitel"
                                                    value={courseData.title}
                                                    name="title"
                                                    onChange={(e) => handleChange(e)}
                                                >
                                                </Form.Control>
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Row>
                                                    <Col xs={6} className="m-0 d-flex align-items-center">
                                                        <Form.Label>Leistungsstand:</Form.Label>
                                                    </Col>

                                                    <Col xs={6}>
                                                        <Form.Select
                                                            type="text"
                                                            value={courseData.performanceLevel}
                                                            name="performanceLevel"
                                                            onChange={(e) => handleChange(e)}
                                                        >
                                                            <option value={0}>Babyschwimmen</option>
                                                            <option value={1}>Wassergewöhnung</option>
                                                            <option value={2}>Anfänger</option>
                                                            <option value={3}>Fortgeschritten</option>
                                                            <option value={35}>Gemischt</option>
                                                            <option value={4}>Leistungskurs</option>
                                                        </Form.Select>
                                                    </Col>
                                                </Row>
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Row>
                                                    <Col xs={9} className="m-0 d-flex align-items-center">
                                                        <Form.Label className="m-0">Maximale Teilnehmer:</Form.Label>
                                                    </Col>
                                                    <Col xs={3}>
                                                        <Form.Control
                                                            className="text-center"
                                                            type="number"
                                                            value={courseData.maxParticipants}
                                                            name="maxParticipants"
                                                            onChange={(e) => handleChange(e)}
                                                        >
                                                        </Form.Control>
                                                    </Col>
                                                </Row>
                                            </Form.Group>

                                            <Form.Group>
                                                <Row>
                                                    <Col xs={9} className="m-0 d-flex align-items-center">
                                                        <Form.Label className="m-0">Kurskosten:</Form.Label>
                                                    </Col>
                                                    <Col xs={3}>
                                                        <Form.Control
                                                            className="text-center"
                                                            type="number"
                                                            value={courseData.cost}
                                                            name="cost"
                                                            onChange={(e) => handleChange(e)}
                                                            placeholder="€"
                                                        >
                                                        </Form.Control>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Card.Body>
                                    </Card>

                                    <Row>
                                        <Col>
                                            <Card className="mt-3 mb-3">
                                                <Card.Header>Ort</Card.Header>
                                                <Card.Body>
                                                    <Form.Group className="mb-3">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Veranstaltungsort"
                                                            value={courseData.locationName}
                                                            name="locationName"
                                                            onChange={(e) => handleChange(e)}
                                                        >
                                                        </Form.Control>
                                                    </Form.Group>

                                                    <Form.Group className="mb-3">
                                                        <Row>
                                                            <Col xs={9} className="m-0 d-flex align-items-center">
                                                                <Form.Control
                                                                    type="text"
                                                                    name="locationStreet"
                                                                    placeholder="Straße"
                                                                    value={courseData.locationStreet}
                                                                    onChange={handleChange}
                                                                >
                                                                </Form.Control>
                                                            </Col>
                                                            <Col xs={3}>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Nr."
                                                                    value={courseData.locationStreetNumber}
                                                                    name="locationStreetNumber"
                                                                    onChange={(e) => handleChange(e)}
                                                                >
                                                                </Form.Control>
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>

                                                    <Form.Group>
                                                        <Row>
                                                            <Col xs={4} className="m-0 d-flex align-items-center">
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="PLZ"
                                                                    value={courseData.locationPostalCode}
                                                                    name="locationPostalCode"
                                                                    onChange={handleChange}
                                                                >
                                                                </Form.Control>
                                                            </Col>
                                                            <Col xs={8}>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Ort"
                                                                    value={courseData.locationCity}
                                                                    name="locationCity"
                                                                    onChange={handleChange}  
                                                                >
                                                                </Form.Control>
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col className="mt-3 mb-3">
                                            <Card>
                                                <Card.Header>Infos</Card.Header>
                                                <Card.Body>
                                                    <Form.Group className="mb-3">
                                                        <Row>
                                                            <Col xs={6}>
                                                                <Form.Label>Lehrer</Form.Label>
                                                            </Col>
                                                            <Col xs={6} className="m-0 d-flex align-items-center">
                                                                <Form.Control
                                                                    name="courseTeacher"
                                                                    type="text"
                                                                    value={courseData.courseTeacher}
                                                                    onChange={handleChange}
                                                                >
                                                                </Form.Control>
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>

                                                    <Form.Group>
                                                        <Row>
                                                            <Col xs={6}>
                                                                <Form.Label>Weitere Informationen</Form.Label>
                                                            </Col>
                                                            <Col xs={6} className="m-0 d-flex align-items-center">
                                                                <Form.Control
                                                                    name="courseInfo"
                                                                    as="textarea"
                                                                    value={courseData.courseInfo}
                                                                    onChange={handleChange}
                                                                    style={{height: '150px', maxHeight: '300px'}}
                                                                >
                                                                </Form.Control>
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>
                                                </Card.Body>
                                            </Card>        
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className="mt-3 mb-3">
                                            <Card>
                                                <Card.Header>Datum & Uhrzeit</Card.Header>
                                                <Card.Body>
                                                    <Form.Group className="mb-3">
                                                        <Row>
                                                            <Col xs={6}>
                                                                <Form.Label>Datum</Form.Label>
                                                            </Col>
                                                            <Col xs={6} className="m-0 d-flex align-items-center">
                                                                <Form.Control
                                                                    name="courseDate"
                                                                    type="date"
                                                                    value={courseData.courseDate}
                                                                    onChange={handleChange}
                                                                >
                                                                </Form.Control>
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>

                                                    <Form.Group>
                                                        <Row>
                                                            <Col xs={6}>
                                                                <Form.Label>Uhrzeit</Form.Label>
                                                            </Col>
                                                            <Col xs={6} className="m-0 d-flex align-items-center">
                                                                <Form.Control
                                                                    name="courseTime"
                                                                    type="time"
                                                                    value={courseData.courseTime}
                                                                    onChange={handleChange}
                                                                >
                                                                </Form.Control>
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>
                                                </Card.Body>
                                            </Card>        
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className="mt-3">
                                            <Card>
                                                <Card.Header>Veröffentlichung</Card.Header>
                                                <Card.Body>
                                                    <Form.Group>
                                                        <Row>
                                                            <Col xs={6}>
                                                                Kurs ist aktiv?
                                                            </Col>

                                                            <Col xs={6} className="text-end">
                                                                <Form.Check
                                                                    type="switch"
                                                                    checked={courseData.approved}
                                                                    onChange={(event) => setCourseData((prev) => ({...prev, approved: event.target.checked}))}
                                                                >
                                                                </Form.Check>
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={12}>
                                <Button 
                                    className="w-100 h-100"
                                    onClick={handleSaveCourse}
                                >
                                    Kurs aktualisieren
                                </Button>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col xs={12}>
                                <Button 
                                    className="w-100 h-100 btn-danger"
                                    onClick={() => handleDeleteCourse(courseData.id)}
                                >
                                    Kurs löschen
                                </Button>
                            </Col>
                            <Col className="text-danger text-center mt-3">Achtung! Der Kurs wird sofort gelöscht!</Col>
                        </Row>
                        <Row>
                            <Col>
                                {error &&
                                    <p className="text-danger text-center">{error}</p>
                                }
                                {success &&
                                    <p className="text-success text-center">{success}</p>
                                }
                            </Col>
                        </Row>
                    </Container>
                </Container>
            }
        </Loader>
    )
}