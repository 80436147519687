import { useParams } from "react-router-dom";
import { Col, Container, Form, Image, Row } from "react-bootstrap"
import mainLogo from '../assets/logo/logo-white.png';
import { Link } from "react-router-dom";
import { postRequest } from "../utils/apiRequest";
import { useState } from "react";
import { sha256 } from "js-sha256";

export const PasswordResetWithCode = () => {
    const { resetCode } = useParams();
    const [resetData, setResetData] = useState({
        passwordResetCode: resetCode,
        password: '',
        passwordConfirm: ''
    });
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setResetData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (resetData.password !== resetData.passwordConfirm) {
            setError('Passwörter stimmen nicht überein.');
        }
        const response = await postRequest(`/reset-password/${resetCode}`, "", {
            ...resetData,
            password: sha256(resetData.password),
            passwordConfirm: ''
        });
        if (response.error) {
            setError(response.error);
        }
        else {
            setError('');
            setMessage('Passwort erfolgreich zurückgesetzt. 🥳');
        }
    }

    return (
        <Container className="min-vh-100 d-flex flex-column align-items-center justify-content-center">
            {!message && (
                <>
                    <Row className="w-100 justify-content-center">
                        <Col xs={12} md={6}>
                            <p className="text-center">
                                Vergib jetzt ein neues Passwort:
                            </p>
                        </Col>
                    </Row>

                    <Row className="w-100 justify-content-center">
                        <Col xs={12} md={6}>
                            <Form onSubmit={handleSubmit}>
                                <Form.Control
                                    type="password"
                                    placeholder="Passwort"
                                    onChange={handleChange}
                                    required
                                    name={"password"}
                                ></Form.Control>

                                <Form.Control
                                    className="mt-3"
                                    type="password"
                                    placeholder="Passwort bestätigen"
                                    onChange={handleChange}
                                    required
                                    name={"passwordConfirm"}
                                ></Form.Control>

                                <Form.Control
                                    className="mt-3 btn btn-primary"
                                    type="submit"
                                    value={"Passwort zurücksetzen"}
                                ></Form.Control>
                            </Form>
                        </Col>
                    </Row>
                </>
            )}

            {message && (
                <Row className="mt-3 justify-content-center text-center w-100">
                    <Col xs={12} md={6}>
                        {message}
                    </Col>
                </Row>
            )}

            {error &&
                <Row className="mt-3 justify-content-center text-center w-100 text-danger">
                    <Col xs={12} md={6}>
                        {error}
                    </Col>
                </Row>
            }

            <Row className="w-100 justify-content-center text-center mt-3">
                <Col xs={12} md={6}>
                    <Link to={"/"}>Zurück</Link>
                </Col>
            </Row>
        </Container>
    );
};
