import { Card, Col, Image, Row } from "react-bootstrap"
import seepferd from '../../../../assets/badges/Seepferdchen.png';
import bronze from '../../../../assets/badges/Bronze.png';
import silber from '../../../../assets/badges/Silber.png';
import gold from '../../../../assets/badges/Gold.png';
import totenkopf1 from '../../../../assets/badges/1hourskull.png';
import totenkopf15 from '../../../../assets/badges/15hourskull.png';
import totenkopf2 from '../../../../assets/badges/2hourskull.png';
import seeraeuber from '../../../../assets/badges/seeraeuber.png';
import wasserratte from '../../../../assets/badges/wasserratte.png';

export const ChildDetailBadgesUser = ({ child }) => {

    const badgeMapping = {
        'seepferd': seepferd,
        'bronze': bronze,
        'silber': silber,
        'gold': gold,
        'totenkopf1': totenkopf1,
        'totenkopf15': totenkopf15,
        'totenkopf2': totenkopf2,
        'seeraeuber': seeraeuber,
        'wasserratte': wasserratte
    }

    return (
        <Card className="mt-3 mb-3">
            <Card.Header>Abzeichen</Card.Header>
            <Card.Body>
                {child.badges.length < 1 &&
                    <Row>
                        <Col>
                            Dieses Kind hat noch keine Abzeichen.
                        </Col>
                    </Row>
                }
                {child.badges.length > 0 &&
                    <Row className="pt-2">
                        {child.badges.map((badge) => {
                            return (
                                <Col 
                                    xs={4}
                                    key={badge.id}
                                    style={{position: 'relative'}}
                                    className="mt-1 mb-3"
                                    >   
                                    <Image 
                                        src={badgeMapping[badge.badge.imagePath]} 
                                        width={'75%'}
                                        title={badge.badge.name}
                                    />
                                </Col>
                            )
                        })}
                    </Row>
                }
            </Card.Body>
        </Card>
    )
}