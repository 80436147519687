import { Col, Container, Form, Image, Row } from "react-bootstrap"
import mainLogo from '../assets/logo/logo-white.png';
import { Link } from "react-router-dom";
import { postRequest } from "../utils/apiRequest";
import { useState } from "react";

export const PasswordReset = () => {

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleChange = (event) => {
        setEmail(event.target.value);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const response = await postRequest('/reset-password', "", {email: email});
        if (response.error) {
            setMessage('Fehler beim Anfordern eines neuen Passworts.');
        }
        else {
            setMessage('Ein Link zum Zurücksetzen deines Passworts wurde an deine E-Mail-Adresse geschickt.');
        }
    }

    return (
        <Container className='min-vh-100 d-flex flex-column align-items-center justify-content-center'>
            
           {!message &&
                <>
                    <Row className="w-100 justify-content-center">
                        <Col xs={12} md={6}>
                            <p className="text-center">Nach dem Absenden dieses Formulars, erhalten Sie einen Link über den Sie Ihr Passwort zurücksetzen können.</p>
                        </Col>
                    </Row>

                    <Row className="w-100 justify-content-center">
                        <Col xs={12} md={6}>
                            <Form onSubmit={handleSubmit}>
                                <Form.Control
                                    type="email"
                                    placeholder="E-Mail-Adresse"
                                    onChange={handleChange}
                                    required
                                >
                                </Form.Control>

                                <Form.Control
                                    className="mt-3 btn btn-primary"
                                    type="submit"
                                    value={'Passwort zurücksetzen'}
                                >
                                </Form.Control>
                            </Form>
                        </Col>
                    </Row>
                </>
            }

            {message &&
                <Row className="mt-3 justify-content-center text-center w-100">
                    <Col xs={12} md={6}>
                        {message}
                    </Col>
                </Row>
            }

            <Row className="w-100 justify-content-center text-center mt-3">
                <Col xs={12} md={6}>
                    <Link to={'/'}>Zurück</Link>
                </Col>
            </Row>
        </Container>
    )
}