import { useState } from "react"
import { Button, Card, CardBody, Col, Form, FormControl, Row } from "react-bootstrap"
import { LockFill, UnlockFill } from "react-bootstrap-icons";
import { postRequest } from "../../../../utils/apiRequest";
import { useOutletContext } from "react-router-dom";
import { userState } from "../../../../utils/atom";
import { Loader } from "../../../../components/loader";

export const UserAddress = ({user, token}) => {

    const [isEditable, setIsEditable] = useState(false);
    const [userData, setUserData] = useState(user);
    const [showToast, setShowToast] = useOutletContext();
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (event) => {
        const {name, value} = event.target;
        setUserData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        const data = {
            id: userData.id,
            firstName: userData.firstName,
            lastName: userData.lastName,
            street: userData.street,
            streetNumber: userData.streetNumber,
            postalCode: userData.postalCode,
            city: userData.city
        }
        const response = await postRequest('/auth/update-user', token, data);
        if (response.error) {
            setShowToast({
                toastStatus: true, 
                toastMessage: response.error,
                toastAppearance: 'danger'
            });
        }
        else {
            setShowToast({
                toastStatus: true, 
                toastMessage: 'Nutzerdaten aktualisiert. 🥳',
                toastAppearance: 'success'
            });
            setIsEditable((prev) => !prev);
        }
        setIsLoading(false);
    }

    return (
        <Loader isActive={isLoading}>
            <Card className="mt-3">
                <Card.Header>
                    <Row>
                        <Col xs={6}>
                            Adressdaten
                        </Col>
                        <Col xs={6} className="text-end">
                            {!isEditable 
                                ? <LockFill onClick={() => setIsEditable((prev) => !prev)} style={{cursor: 'pointer'}} /> 
                                : <UnlockFill onClick={() => setIsEditable((prev) => !prev)} style={{cursor: 'pointer'}} />
                            }
                        </Col>
                    </Row>
                </Card.Header>
                <CardBody>
                    <Row>
                        <Col xs={12} md={6} className="mb-3">
                            <Form.Label>Vorname</Form.Label>
                            <FormControl
                                type="text"
                                value={userData.firstName}
                                disabled={!isEditable}
                                name="firstName"
                                onChange={handleChange}
                            >
                            </FormControl>
                        </Col>

                        <Col xs={12} md={6} className="mb-3">
                            <Form.Label>Nachname</Form.Label>
                            <FormControl
                                type="text"
                                value={userData.lastName}
                                disabled={!isEditable}
                                name="lastName"
                                onChange={handleChange}
                            >
                            </FormControl>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={8} className="mb-3">
                            <Form.Label>Straße</Form.Label>
                            <FormControl
                                type="text"
                                value={userData.street}
                                disabled={!isEditable}
                                name="street"
                                onChange={handleChange}
                            >
                            </FormControl>
                        </Col>

                        <Col xs={4} className="mb-3">
                            <Form.Label>Nr.</Form.Label>
                            <FormControl
                                type="text"
                                value={userData.streetNumber}
                                disabled={!isEditable}
                                name="streetNumber"
                                onChange={handleChange}
                            >
                            </FormControl>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={4} className="mb-3">
                            <Form.Label>PLZ</Form.Label>
                            <FormControl
                                type="text"
                                value={userData.postalCode}
                                disabled={!isEditable}
                                name="postalCode"
                                onChange={handleChange}
                            >
                            </FormControl>
                        </Col>

                        <Col xs={8} className="mb-3">
                            <Form.Label>Ort</Form.Label>
                            <FormControl
                                type="text"
                                value={userData.city}
                                disabled={!isEditable}
                                name="city"
                                onChange={handleChange}
                            >
                            </FormControl>
                        </Col>
                    </Row>      
                </CardBody>
                <Card.Footer className="p-3">
                    <Button
                        disabled={!isEditable}
                        onClick={handleSubmit}
                    >
                        Änderungen speichern
                    </Button>
                </Card.Footer>
            </Card>
        </Loader>
    )
}   