import { Card, Col, Row } from "react-bootstrap"
import { formatDateTimeToDDMMYYYY } from '../../../../../utils/formatDateTime';

export const ChildDetailGeneral = ({ child }) => {
    return (
        <Card className="mt-3 mb-3">
            <Card.Header>Allgemein</Card.Header>
            <Card.Body>
                <Row>
                    <Col xs={4} className="mb-3">Name:</Col>
                    <Col xs={8} className="text-end">{child.firstName} {child.lastName}</Col>
                </Row>
                <Row>
                    <Col xs={4} className="mb-3" >Geburtsdatum:</Col>
                    <Col xs={8} className="text-end">{formatDateTimeToDDMMYYYY(new Date(child.birthdate).toISOString())}</Col>
                </Row>
                <Row>
                    <Col xs={4}>Hinweis:</Col>
                    <Col xs={8} className="text-end">{child.note ? child.note : 'Kein Hinweis vorhanden'}</Col>
                </Row>
            </Card.Body>
        </Card>
    )
}