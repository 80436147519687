import { Col, Image, Modal, Row, Stack } from "react-bootstrap"
import seepferd from '../../../../../assets/badges/Seepferdchen.png';
import bronze from '../../../../../assets/badges/Bronze.png';
import silber from '../../../../../assets/badges/Silber.png';
import gold from '../../../../../assets/badges/Gold.png';
import totenkopf1 from '../../../../../assets/badges/1hourskull.png';
import totenkopf15 from '../../../../../assets/badges/15hourskull.png';
import totenkopf2 from '../../../../../assets/badges/2hourskull.png';
import seeraeuber from '../../../../../assets/badges/seeraeuber.png';
import wasserratte from '../../../../../assets/badges/wasserratte.png';
import { getRequest, postRequest } from "../../../../../utils/apiRequest";
import { useQuery } from "@tanstack/react-query";
import { useOutletContext } from "react-router-dom";
import { Loader } from "../../../../../components/loader";

export const AddBadgeModal = ({ show, handleClose, child, token, refetch }) => {

    const [showToast, setShowToast] = useOutletContext();

    const badgeQuery = useQuery({
        queryKey: ["all-badges-admin"],
        queryFn: () => getRequest(`/auth/all-badges`, token),
    });
    const { data } = badgeQuery;
    
    const badgeMapping = {
        'seepferd': seepferd,
        'bronze': bronze,
        'silber': silber,
        'gold': gold,
        'totenkopf1': totenkopf1,
        'totenkopf15': totenkopf15,
        'totenkopf2': totenkopf2,
        'seeraeuber': seeraeuber,
        'wasserratte': wasserratte
    }

    const handleBadgeClick = async (id) => {
        const data = {
            childId: child.id,
            badgeId: id
        }
        const response = await postRequest('/auth/add-badge-to-child', token, data);
        if (response.error) {
            setShowToast({
                toastStatus: true, 
                toastMessage: response.error,
                toastAppearance: 'danger'
            });
        }
        else {
            setShowToast({
                toastStatus: true, 
                toastMessage: 'Abzeichen erfolgreich vergeben. 🥳',
                toastAppearance: 'success'
            });
            refetch();
            handleClose();
        }
    }

    return (
        <Loader isActive={badgeQuery.isLoading || badgeQuery.isFetching}>
            {data &&
                <Modal 
                    show={show} 
                    onHide={handleClose}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Abzeichen vergeben</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="pt-3">
                            {data.map((badge) => {
                                return (
                                    <Col 
                                        xs={4} 
                                        className="mb-3"
                                        key={badge.id}
                                        onClick={() => handleBadgeClick(badge.id)}
                                    >
                                        <Stack
                                            style={{cursor: 'pointer'}}
                                        >
                                            <Image src={badgeMapping[badge.imagePath]} />
                                            <Col className="text-center">{badge.name}</Col>
                                        </Stack>
                                    </Col>
                                )
                            })}
                        </Row>
                    </Modal.Body>
                </Modal>
            }
        </Loader>
    )
}