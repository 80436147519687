import { ArrowLeft } from "react-bootstrap-icons"
import { Loader } from "../../../components/loader"
import { Alert, Card, Col, Container, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { useRecoilValue } from "recoil"
import { userState } from "../../../utils/atom"
import { getRequest } from "../../../utils/apiRequest"
import { formatDateTimeToDDMMYYYY } from "../../../utils/formatDateTime"

export const UnpaidEnrollments = () => {

    const { token } = useRecoilValue(userState);
    const navigate = useNavigate();

    const unpaidEnrollmentsQuery = useQuery({
        queryKey: ['unpaid-enrollments'],
        queryFn: () => getRequest(`/auth/unpaid-enrollments`, token)
    });
    const { data } = unpaidEnrollmentsQuery;

    return (
        <Loader isActive={false}>
            {data &&
                <Container fluid>
                    <Row className="border-0 p-3 bg-dark-subtle border-bottom border-white mb-3">
                        <Col xs={2} className="p-0">
                            <ArrowLeft
                                size={24}
                                onClick={() => navigate(-1)}
                                style={{cursor: 'pointer'}}
                            />
                        </Col>
                        <Col 
                            xs={8}
                            className="text-center d-flex align-items-center justify-content-center"
                        >
                            <h5 className="m-0">Offene Kurskosten</h5>
                        </Col>
                        <Col xs={2}></Col>
                    </Row>

                    <Container>
                        {data.length < 1 &&
                            <>
                                <Row>
                                    <Col className="text-center mb-3">
                                        Keine offenen Kurskosten. 🥳
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Alert variant="info">
                                            <Alert.Heading>
                                                Info
                                            </Alert.Heading>
                                            <p className="m-0">
                                                Kurskosten zählen als offen, sobald ein Kurs in der Vergangenheit liegt und der Zahlungsstatus weiterhin "offen" ist.
                                            </p>
                                        </Alert>
                                    </Col>
                                </Row>
                            </>
                        }

                        {data.length > 0 &&
                            (data.map((enrollment) => {
                                return (
                                    <Row 
                                        key={enrollment.id} 
                                        className="border p-3 mb-3 rounded border-danger"
                                        onClick={() => navigate(`/course/${enrollment.course.id}`)}
                                        style={{cursor: 'pointer'}}
                                    >
                                        <Col xs={8}>{enrollment.child.firstName} {enrollment.child.lastName}</Col>
                                        <Col xs={4} className="text-end">{enrollment.cost} €</Col>
                                        <Col xs={6}>Offen seit:</Col>
                                        <Col xs={6} className="text-end">{formatDateTimeToDDMMYYYY(enrollment.course.date)}</Col>
                                    </Row>
                                )
                            }))
                        }
                    </Container>
                </Container>
            }
        </Loader>
    )
}