import { Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"

//TODO: Add links
export const Footer = () => {
    return (
        <Row>
            <Col className="d-flex gap-3 justify-content-center">
                <Link to={"/imprint"}>Impressum</Link>
            </Col>
        </Row>
    )
}