import { useEffect, useState } from "react"
import { Button, Card, Col, Form, Row } from "react-bootstrap"
import { postRequest } from "../../../../utils/apiRequest";
import { useOutletContext } from "react-router-dom";

export const UserBroadcastOptin = ({ user, token, isAdmin }) => {

    const [hasWhatsAppAllowed, setHasWhatsappAllowerd] = useState(user.hasWhatsAppAllowed);
    const [hasChanged, setHasChanged] = useState(false);
    const [showToast, setShowToast] = useOutletContext();

    useEffect(() => {
        setHasChanged(hasWhatsAppAllowed !== user.hasWhatsAppAllowed);
    }, [hasWhatsAppAllowed]);

    const handleChange = (e) => {
        setHasWhatsappAllowerd(e.target.checked);
    }

    const handleSubmit = async () => {
        const data = {
            id: user.id,
            hasWhatsAppAllowed: hasWhatsAppAllowed
        }
        const response = await postRequest('/auth/update-whatsapp-optin', token, data);
        if (response.error) {
            setShowToast({
                toastStatus: true,
                toastMessage: response.error,
                toastAppearance: 'danger'
            })
        }
        else {
            setShowToast({
                toastStatus: true,
                toastMessage: 'Einstellungen erfolgreich aktualisiert. 🥳',
                toastAppearance: 'success'
            })
        }
    }

    return (
        <Card className="mt-3">
            <Card.Header>
                Whatsapp-Broadcast
            </Card.Header>
            <Card.Body>
                {!isAdmin &&
                    <>
                        <Row>
                            <Col xs={12}>
                                Wenn du im Whatsapp-Broadcast angemeldet bist, erhältst du Informationen zu neuen Kursen und weiteren wichtigen Ankündigungen bequem per Whatsapp.
                            </Col>
                        </Row>
                        <Row className="mt-3">
                                <Col xs={9}>
                                    Whatsapp-Broadcast aktiv
                                </Col>
                                <Col xs={3} className="text-end">
                                    <Form.Check
                                        type="switch"
                                        checked={hasWhatsAppAllowed}
                                        onChange={handleChange}
                                    >

                                    </Form.Check>
                                </Col>
                        </Row>
                    </>
                }
            </Card.Body>
            <Card.Footer className="p-3">
                <Button onClick={handleSubmit} disabled={!hasChanged}>Änderungen speichern</Button>
            </Card.Footer>
        </Card>
    )
}