import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import { Loader } from "../loader"
import { postRequest } from "../../utils/apiRequest";
import { useEffect, useState } from "react";
import { v4 } from 'uuid';
import { useOutletContext } from "react-router-dom";

export const WaitingRoomEnrollmentModal = ({showWaitingRoomEnrollment, setShowWaitingRoomEnrollment, data, userData, token, courseRefetch, userRefetch}) => {

    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showToast, setShowToast] = useOutletContext();
    const [formData, setFormData] = useState({
        id: v4(),
        childId: '',
        courseId: '',
    });

    useEffect(() => {
        setError('');
    }, []);

    useEffect(() => {
        setFormData((prev) => {
            return {
                ...prev,
                childId: userData.children[0].id,
                courseId: data.id
            }
        });
    }, [userData, data]);

    
    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        const response = await postRequest('/auth/add-to-waitingroom', token, formData);
        if (response.error) {
            setError(response.error);
        }
        else {
            setShowToast({
                toastStatus: true,
                toastMessage: 'Deine Kursanmeldung war erfolgreich. 🥳',
                toastAppearance: 'success'
            });
            courseRefetch();
            userRefetch();
            setShowWaitingRoomEnrollment(false);   
        }
        setIsLoading(false);
    }

    const handleSelectChange = (event) => {
        setFormData((prev) => {
            return {
                ...prev,
                childId: event.target.value
            }
        })
    }

    return (
        <Loader isActive={isLoading}>
            <Modal 
                show={showWaitingRoomEnrollment} 
                onHide={() => setShowWaitingRoomEnrollment(false)} 
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Warteliste</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Row>
                            <Col xs={12} className="mb-3">
                                <Form.Label className="mb-3">Wen möchtest du zur Warteliste hinzufügen?</Form.Label>
                                <Form.Select
                                    onChange={handleSelectChange}
                                    name="childId"
                                    value={formData.childId}
                                >
                                    {userData.children.map((child) => {
                                        if (data.performanceLevel < 4 || data.performanceLevel === 35 || (data.performanceLevel === 4 && child.performanceLevel === 4)) {
                                            return (
                                                <option 
                                                    key={child.id} 
                                                    value={child.id}
                                                >
                                                    {`${child.firstName} ${child.lastName}`}
                                                </option>
                                            )
                                        }
                                    })}
                                </Form.Select>
                            </Col>
                        </Row>

                        <Row >
                            <Col xs={12}>
                                Durch eine Eintragung auf die Warteliste, wirst du per E-Mail benachrichtigt, wenn in diesem Kurs ein Platz frei wird.
                            </Col>
                        </Row>    

                        {error &&
                            <Row>
                                <Col className="mt-3 text-center">
                                    <p className="text-danger">
                                        {error}
                                    </p>
                                </Col>
                            </Row>
                        }
                    </Modal.Body>

                    <Modal.Footer>
                        <Row>
                            <Col>
                                <Form.Control
                                    type="submit"
                                    value={"Anmelden"}
                                    className="bg-success border-0"
                                ></Form.Control>
                            </Col>
                            <Col>
                                <Button 
                                    className="btn-danger"
                                    onClick={() => setShowWaitingRoomEnrollment(false)}
                                >
                                    Abbrechen
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Form>
            </Modal>
        </Loader>
    )
}