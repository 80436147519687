import { Col, Container, Row, Stack } from "react-bootstrap"
import { CalendarPlusFill, PersonFillAdd, SendPlusFill } from "react-bootstrap-icons"
import '../styles/quick-settings/quick-settings.scss';
import { useNavigate } from "react-router-dom";

//TODO: ADD LOGIC
export const QuickSettings = () => {

    const navigate = useNavigate();

    return (
        <Container>
            <Row>
                <Col className="p-3 border rounded d-flex justify-content-center gap-5">
                    <PersonFillAdd title="Benutzer anlegen" size={24} style={{cursor: 'pointer'}} className="quick-settings-icon" />
                    <CalendarPlusFill title="Kurs planen" size={24} style={{cursor: 'pointer'}} className="quick-settings-icon" onClick={() => navigate('/new-course')}/>
                    <SendPlusFill title="News anlegen" size={24} style={{cursor: 'pointer'}} className="quick-settings-icon" onClick={() => navigate('/new-news')} />
                </Col>
            </Row>
        </Container>
    )
}