import { Button, Card, Col, Image, Row } from "react-bootstrap"
import seepferd from '../../../../../assets/badges/Seepferdchen.png';
import bronze from '../../../../../assets/badges/Bronze.png';
import silber from '../../../../../assets/badges/Silber.png';
import gold from '../../../../../assets/badges/Gold.png';
import totenkopf1 from '../../../../../assets/badges/1hourskull.png';
import totenkopf15 from '../../../../../assets/badges/15hourskull.png';
import totenkopf2 from '../../../../../assets/badges/2hourskull.png';
import seeraeuber from '../../../../../assets/badges/seeraeuber.png';
import wasserratte from '../../../../../assets/badges/wasserratte.png';
import { AddBadgeModal } from "./addBadgeModal";
import { useState } from "react";
import { X } from "react-bootstrap-icons"
import { ChildDetailRemoveBadgeConfirmModal } from "./childDetailRemoveBadgeConfirmModal";

export const ChildDetailBadges = ({ child, token, refetch }) => {

    const badgeMapping = {
        'seepferd': seepferd,
        'bronze': bronze,
        'silber': silber,
        'gold': gold,
        'totenkopf1': totenkopf1,
        'totenkopf15': totenkopf15,
        'totenkopf2': totenkopf2,
        'seeraeuber': seeraeuber,
        'wasserratte': wasserratte
    }

    const [showModal, setShowModal] = useState(false);
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const [badgeToDeleteId, setBadgeToDeleteId] = useState('');

    const handleClose = () => {
        setShowModal(false);
    }

    const handleDeleteClick = (id) => {
        setShowDeleteConfirmModal(true);
        setBadgeToDeleteId(id);
    }

    return (
        <>
            <Card className="mt-3 mb-3">
                <Card.Header>Abzeichen</Card.Header>
                <Card.Body>
                    {child.badges.length < 1 &&
                        <Row>
                            <Col>
                                Dieses Kind hat noch keine Abzeichen.
                            </Col>
                        </Row>
                    }
                    {child.badges.length > 0 &&
                        <Row>
                            {child.badges.map((badge) => {
                                return (
                                    <Col 
                                        xs={4}
                                        key={badge.id}
                                        style={{position: 'relative'}}
                                        >   
                                        <div
                                            style={{cursor: "pointer"}}
                                        >
                                            {<X 
                                                onClick={() => handleDeleteClick(badge.id)} 
                                            />}
                                        </div>
                                        <Image 
                                            src={badgeMapping[badge.badge.imagePath]} 
                                            width={'75%'}
                                        />
                                    </Col>
                                )
                            })}
                        </Row>
                    }
                </Card.Body>
                <Card.Footer className="p-3">
                    <Button onClick={() => setShowModal(true)}>Abzeichen vergeben</Button>
                </Card.Footer>
            </Card>

            <AddBadgeModal 
                show={showModal}
                handleClose={handleClose}
                child={child}
                token={token}
                refetch={refetch}
            />

            <ChildDetailRemoveBadgeConfirmModal 
                showDeleteConfirmModal={showDeleteConfirmModal}
                setShowDeleteConfirmModal={setShowDeleteConfirmModal}
                badgeToDeleteId={badgeToDeleteId}
                token={token}
                refetch={refetch}
            />
        </>
    )
}