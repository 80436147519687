import { useQuery } from "@tanstack/react-query"
import { Badge, Card, Col, ListGroup, ListGroupItem, Row } from "react-bootstrap"
import { getRequest } from "../utils/apiRequest"
import { useRecoilValue } from "recoil";
import { userState } from "../utils/atom";
import { Loader } from "./loader";
import '../styles/card-list/card-list.scss'
import { useNavigate } from "react-router-dom";

export const NewRegistrations = () => {

    const MAX_REGISTRATIONS_TO_SHOW = 4;
    const { token } = useRecoilValue(userState);
    const navigate = useNavigate();

    const newRegistrationsQuery = useQuery({
        queryKey: ['newRegistrationsQuery'],
        queryFn: () => getRequest('/auth/getnewregistrations', token)
    });
    const { data } = newRegistrationsQuery;

    return (
        <Loader isActive={newRegistrationsQuery.isFetching || newRegistrationsQuery.isLoading}>
            {data &&
                <Card className="mt-3 p-0">
                    <Card.Header style={{cursor: 'pointer'}} className="p-3" onClick={() => navigate('/all-registrations')}>
                        <Row className="align-items-center">
                            <Col xs={9}><h5 className="m-0">Neue Registrierungen</h5></Col>
                            <Col xs={3}
                                className="text-end"
                            >
                                <h5 className="m-0"><Badge>{data.length}</Badge></h5>
                            </Col> 
                        </Row>   
                    </Card.Header>
                    <Card.Body className="p-0">
                        {data.length < 1 &&
                            <p className="m-0 p-3">Keine offenen Registrierungen 🥳</p>
                        }
                        {data.length > 0 &&
                            <ListGroup className="card-list">
                                {data.map((registration, index) => {
                                    if (index < MAX_REGISTRATIONS_TO_SHOW) {
                                        return (
                                            <ListGroupItem 
                                                className="card-list-item"
                                                key={registration.id}
                                                onClick={() => navigate(`/registration/${registration.id}`)}
                                            >
                                                {`${registration.firstName} ${registration.lastName}`}
                                            </ListGroupItem>
                                        )
                                    }
                                })}
                                <ListGroupItem
                                    className="card-list-item"
                                    key="card-list-item-show-more"
                                    onClick={() => navigate('/all-registrations')}
                                >
                                    {`Alle anzeigen (${data.length})`}
                                </ListGroupItem>
                            </ListGroup>         
                        }
                    </Card.Body>
                </Card>
            }
        </Loader>
    )
}