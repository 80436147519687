import { Card, Col, Row, Table } from "react-bootstrap"
import { useNavigate } from "react-router-dom"

export const UserChildren = ({user}) => {
    
    const navigate = useNavigate();

    return (
        <Card className="mt-3">
            <Card.Header>
                <Row>
                    <Col>
                        Kinder
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body className="p-0">
                {user.children.length <= 0 
                ? 
                    <p className="m-0 p-3">Dieser Nutzer hat noch keine Kinder angelegt.</p>
                : 
                    <Table hover className="m-0">
                        <tbody className="no-border-on-last-child">
                        {user.children.map((child) => {
                        return (
                            <tr 
                                key={child.id}
                                className="bg-none"
                                style={{cursor: "pointer"}}
                                onClick={() => navigate(`/child/${child.id}`)}
                            >
                                <th style={{backgroundColor: 'none'}}>
                                    {child.firstName} {child.lastName}
                                </th>

                            </tr>
                        )
                        })}
                        </tbody>
                    </Table>
                }
            </Card.Body>

        </Card>
    )
}