import { useQuery } from "@tanstack/react-query";
import { getRequest, postRequest } from "../../utils/apiRequest";
import { useRecoilValue } from "recoil";
import { userState } from "../../utils/atom";
import { Loader } from "../../components/loader";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { formatDateTimeToDDMMYY } from "../../utils/formatDateTime";
import '../../styles/default-table/default-table.scss';
import { ArrowLeft, Check, CheckAll, X } from "react-bootstrap-icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const PageAllRegistrations = () => {

    const { token } = useRecoilValue(userState);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const newRegistrationsQuery = useQuery({
        queryKey: ['newRegistrationsQuery'],
        queryFn: () => getRequest('/auth/getnewregistrations', token)
    });
    const { data } = newRegistrationsQuery;

    const allowAllRegistrations = async () => {
        setIsLoading(true);
        const response = await getRequest('/auth/allowallregistrations', token);
        if (response.error) {
            setError(response.error);
        }
        newRegistrationsQuery.refetch();
        setIsLoading(false);
    }

    const handleAcceptClick = async (e) => {
        e.stopPropagation();
        setIsLoading(true);
        await getRequest(`/auth/allowregistrationbyid/${e.target.getAttribute('data-user-id')}`, token);
        setIsLoading(false);
        newRegistrationsQuery.refetch();
    }

    const handleDeleteClick = async (e) => {
        e.stopPropagation();
        setIsLoading(true);
        await postRequest('/auth/deleteuser', token, {id: e.target.getAttribute('data-user-id')});
        setIsLoading(false);
        newRegistrationsQuery.refetch();
    }

    return (
        <Loader isActive={newRegistrationsQuery.isFetching || newRegistrationsQuery.isLoading || isLoading}>
            {data &&
                <Container fluid>  
                    <Row className="border-0 p-3 bg-dark-subtle border-bottom border-white mb-3">
                            <Col xs={2} className="p-0">
                                <ArrowLeft
                                    size={24}
                                    onClick={() => navigate(-1)}
                                    style={{cursor: 'pointer'}}
                                />
                            </Col>
                            <Col 
                                xs={8}
                                className="text-center d-flex align-items-center justify-content-center"
                            >
                                <h5 className="m-0">Neue Registrierungen</h5>
                            </Col>
                            <Col xs={2}>
                                
                            </Col>
                        
                        </Row>
                    <Container>
                        {data.length > 0 &&
                            <>
                                <Row className="justify-content-center">
                                    <Col xs={12} md={6}>
                                        <Table hover className="all-registrations-table default-table border p-3">
                                            <thead>
                                                <tr>
                                                    <th className="p-3">Name</th>
                                                    <th className="p-3">Datum</th>
                                                    <th className="p-3">Aktionen</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.map((registration) => {
                                                    return (
                                                        <tr 
                                                            key={registration.id} 
                                                            className="p-3"
                                                            onClick={() => navigate(`/registration/${registration.id}`)}
                                                        >
                                                            <th>{`${registration.firstName} ${registration.lastName}`}</th>
                                                            <th>{formatDateTimeToDDMMYY(registration.createdAt)}</th>
                                                            <th>
                                                                <Row className="flex-nowrap ms-3">
                                                                    <Col className="p-0">
                                                                        <Check 
                                                                            size={28} 
                                                                            onClick={handleAcceptClick}
                                                                            title="Benutzer freigeben"
                                                                            data-user-id={registration.id}
                                                                        />
                                                                    </Col>
                                                                    <Col className="p-0">
                                                                        <X 
                                                                            size={28}
                                                                            onClick={handleDeleteClick}
                                                                            title="Benutzer löschen"
                                                                            data-user-id={registration.id}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </th>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-center mt-3">
                                        <Button onClick={() => allowAllRegistrations(token)}>
                                            <CheckAll size={24}></CheckAll> Alle freigeben
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        }
                        {data.length < 1 &&
                            <Row>
                                <Col>
                                    <p className="text-center mt-3">Keine offenen Registrierungen 🥳</p>
                                </Col>
                            </Row>
                        }
                        {error &&
                            <p className="text-danger mt-3 text-center">{error}</p>
                        }
                    </Container>
                </Container>
            }
        </Loader>
    )
}