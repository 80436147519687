import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.scss';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { Root } from './pages/root';
import { RecoilRoot } from 'recoil';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();
const router = createBrowserRouter([
    {
        path: "/*",
        element: <Root />,
    }
]);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
        <RecoilRoot>
            <RouterProvider router={router} />
        </RecoilRoot>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
