import { Card, Col, Row } from "react-bootstrap"

export const UserWhatsappBroadcast = ({user}) => {
    return (
        <Card className="mt-3">
            <Card.Header>
                Whatsapp-Broadcast
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col xs={6}>
                        Whatsapp-Broadcast:
                    </Col>
                    <Col xs={6} className="text-end">
                        {user.hasWhatsAppAllowed ? 'Zugestimmt' : 'Abgelehnt'}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}