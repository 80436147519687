import { Card, Col, Container, Row } from "react-bootstrap"
import { Loader } from "../../../components/loader"
import { TitleWithBackButton } from "../../../components/title-with-back-button/titleWithBackButton"
import { useRecoilValue } from "recoil";
import { userState } from "../../../utils/atom";
import { useQuery } from "@tanstack/react-query";
import { getRequest } from "../../../utils/apiRequest";
import { useNavigate } from "react-router-dom";
import { formatDateTime, formatDateTimeToDDMMYY } from "../../../utils/formatDateTime";

export const WaitingRoomUser = () => {

    const navigate = useNavigate();
    const { token } = useRecoilValue(userState);
    const waitingRoomQuery = useQuery({
        queryKey: ['waiting-room-query'],
        queryFn: () => getRequest(`/auth/waiting-rooms-by-user`, token)
    });
    const { data } = waitingRoomQuery;

    return (
        <Loader isActive={waitingRoomQuery.isLoading || waitingRoomQuery.isFetching}>
            <Container fluid>
                <TitleWithBackButton title={'Deine Wartelisten'} />
            </Container>
            {(data && Object.keys(data).length > 0) &&
                <Container>
                    {Object.keys(data).map((child) => {
                        if (data[child].length > 0) {
                            return (
                                <Row>
                                    <Col xs={12} className="mt-3">
                                        <Card className="mt-3">
                                            <Card.Header>
                                                {child}
                                            </Card.Header>
                                            <Card.Body className="p-0 overflow-hidden no-border-on-last-child">
                                                {data[child].map((course) => {
                                                    return (
                                                        <Row 
                                                            className="p-3 border-bottom hoverable-title"
                                                            onClick={() => navigate(`/course/${course.id}`)}
                                                            >
                                                            <Col xs={6}>
                                                                {course.title}
                                                            </Col>
                                                            <Col xs={6} className="text-end">
                                                                {formatDateTimeToDDMMYY(course.date)}
                                                            </Col>
                                                        </Row>
                                                    )
                                                })}
                                            </Card.Body>
                                        </Card>                                    
                                    </Col>
                                </Row>                        
                            )
                        }
                    })}
                </Container>
            }
        </Loader>
    )
}