import { Outlet, useNavigate } from "react-router-dom"
import { OffcanvasNavigation } from "../components/offcanvasNavigation"
import { Col, Container, Image, Row, Toast, ToastContainer } from "react-bootstrap"
import { Footer } from "../components/footer";
import { useState } from "react";
import mainLogo from '../assets/logo/logo-white.png';

export const LoginRoot = () => {

    const navigate = useNavigate();
    const [showToast, setShowToast] = useState({
        toastStatus: false, 
        toastMessage: 'Default Toast',
        toastAppearance: 'success'
    });

    return (
        <>
            <Container fluid>
                <ToastContainer
                    className="p-3"
                    position="top-center"
                >
                    <Toast
                        onClose={() => setShowToast({toastStatus: false})} 
                        show={showToast.toastStatus}
                        delay={3000}
                        autohide
                        bg={showToast.toastAppearance}
                    >
                        <Toast.Body>{showToast.toastMessage}</Toast.Body>
                    </Toast>
                </ToastContainer>

                <Row className="p-3 border-bottom border-white d-flex align-items-center bg-dark">
                    <Col xs={6}>
                        <Image 
                            src={mainLogo} 
                            height={50}
                            onClick={() => navigate('/')}
                            style={{cursor: 'pointer'}}
                        />
                    </Col>
                    <Col 
                        xs={6} 
                        className="text-end"
                    >
                        <OffcanvasNavigation />
                    </Col>
                </Row>

                <Row style={{minHeight: '80vh'}}>
                    <Col className="p-0">
                        <Outlet context={[showToast, setShowToast]} />
                    </Col>
                </Row>

                <Row 
                    className="border-top border-white bg-dark mt-3"
                    style={{maxHeight: '100px'}}
                >
                    <Col className="p-3 text-center">
                        <Footer />
                    </Col>
                </Row>
            </Container>
        </>  
    )
}