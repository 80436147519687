import { Alert, Button, Card, Col, Container, ListGroup, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../../../components/loader";
import { ArrowLeft } from "react-bootstrap-icons";
import { useQuery } from "@tanstack/react-query";
import { postRequest } from "../../../utils/apiRequest";
import { useRecoilValue } from "recoil";
import { userState } from "../../../utils/atom";
import { formatDateTimeToDDMMYY, formatDateTimeToHHMM } from "../../../utils/formatDateTime";
import '../../../styles/card-list/card-list.scss';
import { useEffect, useState } from "react";
import { EnrollmentDetail } from "../../../components/modals/enrollmentDetail";
import { TitleWithBackButton } from "../../../components/title-with-back-button/titleWithBackButton";

export const CourseDetail = () => {

    const { id } = useParams();
    const { token } = useRecoilValue(userState);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [index, setIndex] = useState(0);
    const [isPastCourse, setIsPastCourse] = useState(false);

    const performanceMapping = {
        0: 'Babyschwimmen',
        1: 'Wassergewöhnung',
        2: 'Anfänger',
        35: 'Gemischt',
        3: 'Fortgeschritten',
        4: 'Leistungskurs'
    }

    const courseDetailQuery = useQuery({
        queryKey: ['course-detail'],
        queryFn: () => postRequest(`/auth/course-detail`, token, {id: id})
    });
    const { data } = courseDetailQuery;
    
    const handleOpenModal = (index) => {
        setShow(true);
        setIndex(index);
    }

    useEffect(() => {
        if (data) {
            if (new Date(data.date) < new Date()) {
                setIsPastCourse(true);
            }
        }
    }, [data]);

    return (
        <Loader isActive={courseDetailQuery.isFetching || courseDetailQuery.isLoading}>
            {data &&
                <>
                    <Container fluid>
                       <TitleWithBackButton title={`${data.title} (${(data.approved && !isPastCourse) ? 'Aktiv' : 'Inaktiv'})`} />
                    </Container>

                    <Container>
                        {isPastCourse &&
                            <Row>
                                <Col xs={12} className="mt-3">
                                    <Alert variant="info">
                                        Dieser Kurs liegt in der Vergangenheit und kann nicht mehr bearbeitet werden.
                                    </Alert>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col className="mt-3 mb-3">
                                <Card 
                                    className={data.performanceLevel === 4 ? "border-warning" : ""}
                                >
                                    <Card.Header>
                                        <Row>
                                            <Col xs={6}>Kursdetails</Col>
                                            {data.performanceLevel === 4 &&
                                                <Col xs={6} className="text-end">
                                                    👑
                                                </Col>
                                            }
                                        </Row>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row className="border-bottom pb-3">
                                            <Col xs={6}>
                                                Datum:
                                            </Col>
                                            <Col xs={6} className="text-end">
                                                {formatDateTimeToDDMMYY(data.date)}
                                            </Col>
                                        </Row>

                                        <Row className="border-bottom pt-3 pb-3">
                                            <Col xs={6}>
                                                Uhrzeit:
                                            </Col>
                                            <Col xs={6} className="text-end">
                                                {formatDateTimeToHHMM(data.date)} Uhr
                                            </Col>
                                        </Row>

                                        <Row className="border-bottom pt-3 pb-3">
                                            <Col xs={4}>
                                            Ort:
                                            </Col>
                                            <Col xs={8} className="text-end">
                                                {data.locationStreet} {data.locationStreetNumber}, {data.locationCity}
                                            </Col>
                                        </Row>

                                        <Row className="pt-3">
                                            <Col xs={6}>
                                            Leistungsniveau:
                                            </Col>
                                            <Col xs={6} className="text-end">
                                                {performanceMapping[data.performanceLevel]}
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                            <Col className="mb-3">
                                <Card>
                                    <Card.Header className="border-bottom-0">Teilnehmerliste</Card.Header>
                                    <Card.Body className="p-0">
                                        {data.participants.length > 0 
                                        
                                        ?
                                            <ListGroup className="rounded-0 card-list">
                                                {data.participants.map((item, index) => {
                                                    return (
                                                        <ListGroup.Item 
                                                            key={item.id}
                                                            onClick={() => navigate(`/child/${item.child.id}`)}
                                                        >
                                                            <Row
                                                                style={{
                                                                    cursor: 'pointer'
                                                                }}
                                                            >
                                                                <Col 
                                                                    xs={6}
                                                                    className="d-flex align-items-center"
                                                                >
                                                                    {item.child.firstName}  {item.child.lastName}
                                                                </Col>
                                                                <Col xs={6} 
                                                                    className="text-end d-flex align-items-center justify-content-end"
                                                                >
                                                                    <Button 
                                                                        className={`w-75 ${item.paymentStatus == "paid" ? "btn-success" : "btn-danger"}`}
                                                                        style={{maxWidth: '100px'}}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            handleOpenModal(index)
                                                                        }}
                                                                    >
                                                                        {item.paymentStatus == "paid" ? "Bezahlt" : "Offen"}
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </ListGroup.Item>
                                                    )
                                                })}
                                            </ListGroup>

                                        :
                                            <Row className="p-3">
                                                <Col>
                                                    Dieser Kurs hat noch keine Teilnehmer.
                                                </Col>
                                            </Row>
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col xs={12} className="mt-3 mb-3">
                                <Card>
                                    <Card.Header>Warteliste</Card.Header>
                                    <Card.Body className="p-0 no-border-on-last-child overflow-hidden">
                                        {data.waitingRooms.children.length == 0 &&
                                            <Row className="p-3">
                                                <Col xs={12}>
                                                    Keine Teilnehmer auf der Warteliste
                                                </Col>
                                            </Row>
                                        }
                                        {data.waitingRooms.children.map((child) => {
                                            return (
                                                <Row 
                                                    className="hoverable-title border-bottom p-3"
                                                    key={child.child.id}
                                                    onClick={() => navigate(`/child/${child.child.id}`)}
                                                >
                                                    <Col xs={12}>
                                                        {`${child.child.firstName} ${child.child.lastName}`}
                                                    </Col>
                                            </Row>)
                                        })}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="mt-3 mb-3" xs={12}>
                                <Button 
                                    onClick={() => navigate(`/edit-course/${id}`)}
                                    disabled={isPastCourse}
                                >
                                    Kurs bearbeiten
                                </Button>            
                            </Col>
                        </Row>
                        
                        {/* EnrollmentDetail Modal - Getting data via onClick={handleOpenModal} */}
                        {data.participants.length > 0 &&
                            <EnrollmentDetail 
                                show={show} 
                                setShow={setShow} 
                                data={data} 
                                index={index} 
                                token={token}
                                refetch={courseDetailQuery.refetch}
                            />
                        }
                        
                    </Container>
                </>
            }
        </Loader>
    )
}