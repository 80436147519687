import { useState } from "react"
import { Alert, Button, Card, Col, Form, Modal, Row } from "react-bootstrap"
import { postRequest } from "../../../../utils/apiRequest";
import { Loader } from "../../../../components/loader";
import { useNavigate, useOutletContext } from "react-router-dom";
import { LockFill, UnlockFill } from "react-bootstrap-icons";

export const UserAdminRights = ({ user, token }) => {

    const [isAdmin, setIsAdmin] = useState(user.isAdmin);
    const [isLoading, setIsLoading] = useState(false);
    const [showToast, setShowToast] = useOutletContext();
    const [isEditable, setIsEditable] = useState(false);
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
    const navigate = useNavigate();

    const handleChange = () => {
        setIsAdmin((prev) => (!prev));
    }

    const handleSubmit = async () => {
        const data = {
            id: user.id,
            isAdmin: isAdmin
        }
        const response = await postRequest('/auth/update-user', token, data);
        if (response.error) {
            setShowToast({
                toastStatus: true, 
                toastMessage: response.error,
                toastAppearance: 'danger'
            });
        }
        else {
            setShowToast({
                toastStatus: true, 
                toastMessage: 'Nutzerdaten aktualisiert. 🥳',
                toastAppearance: 'success'
            });
        }
        setIsLoading(false);
    }

    const handleUserDelete = async (id) => {
        const response = await postRequest("/auth/deleteuser", token, {
            id: id,
        });
        if (response.error) {
            console.log(response.error);
        } else {
            navigate("/all-users");
        }
    };

    return (
        <Loader isActive={isLoading}>
            <Card className="mt-3">
                <Card.Header>
                    <Row>
                        <Col xs={6}>
                            Administration
                        </Col>
                        <Col xs={6} className="text-end">
                            {!isEditable 
                                ? <LockFill onClick={() => setIsEditable((prev) => !prev)} style={{cursor: 'pointer'}} /> 
                                : <UnlockFill onClick={() => setIsEditable((prev) => !prev)} style={{cursor: 'pointer'}} />
                            }
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col xs={6}>
                            Adminrechte:
                        </Col>
                        <Col xs={6} className="text-end">
                            <Form.Check
                                type="switch"
                                checked={isAdmin}
                                onChange={handleChange}
                                disabled={!isEditable}
                            >
                            </Form.Check>
                        </Col>
                    </Row>
                    {isAdmin &&
                        <Row>
                            <Col xs={12} className="text-danger mt-3">
                                <Alert variant="danger">
                                    Achtung! Diese Einstellung gibt dem Benutzer administrative Rechte.
                                </Alert>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col className="mt-3">
                            <Button
                                className="btn-danger"
                                onClick={() => setShowDeleteConfirmModal(true)}
                                disabled={!isEditable}
                            >
                                Benutzer löschen
                            </Button>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer className="p-3">
                    <Button
                        onClick={handleSubmit}
                        disabled={!isEditable}
                    >
                        Änderungen speichern
                    </Button>
                </Card.Footer>
            </Card>

            <Modal
                centered
                show={showDeleteConfirmModal}
                onHide={() => setShowDeleteConfirmModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Benutzer löschen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            Möchtest du den Benutzer wirklich löschen?
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => setShowDeleteConfirmModal(false)}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        className="btn-danger"
                        onClick={() => handleUserDelete(user.id)}
                    >
                        Benutzer löschen
                    </Button>
                </Modal.Footer>
            </Modal>
        </Loader>
    )
}