import { Card, Col, Row } from "react-bootstrap"

export const ChildDetailPerformanceUser = ({ child }) => {

    const performanceMapping = {
        0: 'Babyschwimmen',
        1: 'Wassergewöhnung',
        2: 'Anfänger',
        35: 'Gemischt',
        3: 'Fortgeschritten',
        4: 'Leistungskurs'
    }

    return (
        <Card className="mt-3 mb-3">
            <Card.Header>Leistungsniveau</Card.Header>
            <Card.Body>
                <Row>
                    <Col xs={12} md={6} className="mb-3">
                        Aktueller Leistungsstand:
                    </Col>
                    <Col xs={12} md={6} className="text-end">
                        {performanceMapping[child.performanceLevel]}
                    </Col>
                </Row>

                <Row>
                    <Col xs={12}>
                        Leistungskommentar:
                    </Col>
                    <Col xs={12}>
                        {child.adminNote}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}