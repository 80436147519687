import { useEffect, useState } from "react";
import { Alert, Button, Col, Modal, Row } from "react-bootstrap";
import { formatDateTimeToDDMMYY } from "../../utils/formatDateTime";
import { useOutletContext } from "react-router-dom";
import { Copy } from "react-bootstrap-icons";

export const PaySingleCourseModal = ({ show, hide, data, courseId }) => {

    const [children, setChildren] = useState([]);
    const [total, setTotal] = useState(0);
    const [showBankInfo, setShowBankInfo] = useState(false);
    const [showToast, setShowToast] = useOutletContext();
    const [paymentReasonString, setPaymentReasonString] = useState('');

    const handlePaypalButton = (total) => {
        window.open(`https://paypal.me/kiddiefyallaboutkids/${total}`)
    }

    const handleBankButton = () => {
        setShowBankInfo(true);
    }

    useEffect(() => {
        const enrolledChildren = data.children
            .map((child) => {
                for (let enrolledCourse of child.enrolledCourses) {
                    if (enrolledCourse.courseId === courseId) {
                        return {
                            child: child,
                            course: enrolledCourse,
                        };
                    }
                }
                return null;
            })
            .filter((item) => item !== null);
        setChildren(enrolledChildren);
    }, [data, courseId]);

    useEffect(() => {
        if (children) {
            const totalSumToPay = children.reduce((acc, child) => {
                return acc + Number(child.course.cost);
            }, 0);
            setTotal(totalSumToPay);
    
            const paymentString = children.map((child) => {
                return ` ${child.child.firstName}`
            }) + ', ' + courseId.split('-')[0]
            setPaymentReasonString(paymentString);
        }

    }, [children]);

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setShowToast({
                    toastStatus: true,
                    toastMessage: 'In die Zwischenablage kopiert ✅',
                    toastAppearance: 'success'
                })
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    };

    return (
        <Modal show={show} onHide={hide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Kurskosten bezahlen</Modal.Title>
            </Modal.Header>
            {children.length > 0 &&
                <Modal.Body>
                    {children.map((child) => {
                        return (
                            <Row key={child.child.id}>
                                <Col xs={6}>
                                    {child.child.firstName} {child.child.lastName}
                                </Col>
                                <Col xs={6} className="text-end">
                                    {child.course.cost} €
                                </Col>
                            </Row>
                        );
                    })}
                    <Row className="border-top mt-3 pt-3">
                        <Col xs={6}>Summe:</Col>
                        <Col xs={6} className="text-end">
                            {total} €
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="mt-3 text-warning">
                            Bitte teile uns bei PayPal-Zahlung die Namen der Kinder und das Kursdatum mit!
                            Dein Zahlungseingang wird normalerweise innerhalb weniger Tage verbucht.
                        </Col>
                    </Row>
                    {showBankInfo &&
                        <>
                            <Row className="border-top mt-3 pt-3">
                                <Col xs={6}>
                                    Kontoinhaber:
                                </Col>
                                <Col 
                                    xs={6}
                                    onClick={() => handleCopy('René Andreas Schmidt')} style={{cursor: 'pointer'}}
                                >
                                    <Row>
                                        <Col xs={9}>
                                            René Andreas Schmidt
                                        </Col>
                                        <Col xs={3}>
                                            <Copy />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={6}>
                                    IBAN:
                                </Col>
                                <Col xs={6} onClick={() => handleCopy('DE14100101230799393394')} style={{cursor: 'pointer'}}>
                                    <Row>
                                        <Col xs={9}>
                                            DE14 1001 0123 0799 3933 94
                                        </Col>
                                        <Col xs={3}>
                                            <Copy />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={6}>
                                    BIC:
                                </Col>
                                <Col xs={6} onClick={() => handleCopy('QNTODEB2XXX')} style={{cursor: 'pointer'}}>
                                    <Row>
                                        <Col xs={9}>
                                            QNTODEB2XXX
                                        </Col>
                                        <Col xs={3}>
                                            <Copy />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col xs={12} sm={6}>
                                    Verwendungszweck:
                                </Col>
                                <Col xs={12} sm={6} onClick={() => handleCopy(paymentReasonString)} style={{cursor: 'pointer'}}>
                                    <Row>
                                        <Col xs={9}>
                                            {paymentReasonString}
                                        </Col>
                                        <Col xs={3}>
                                            <Copy />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                    }
                </Modal.Body>
            }
            <Modal.Footer>
                <Button onClick={handleBankButton}>Überweisung</Button>
                <Button onClick={() => handlePaypalButton(total)}>Paypal</Button>
            </Modal.Footer>
        </Modal>
    );
};
