import { useEffect, useState } from "react"
import { Button, Card, Col, Form, Row } from "react-bootstrap"
import { postRequest } from "../../../../../utils/apiRequest";
import { useOutletContext } from "react-router-dom";

export const ChildDetailPerformance = ({ child, token, refetch }) => {

    const [hasChanged, setHasChanged] = useState(false);
    const [childPerformanceLevel, setChildPerformanceLevel] = useState(child.performanceLevel);
    const [adminNote, setAdminNote] = useState(child.adminNote ? child.adminNote : '');
    const [showToast, setShowToast] = useOutletContext();

    const handleSelectChange = (event) => {
        setHasChanged(true);
        setChildPerformanceLevel(event.target.value);
    }

    const handleTextAreaChange = (event) => {
        setHasChanged(true);
        setAdminNote(event.target.value);
    }

    const handleSaveButton = async () => {
        const data = {
            id: child.id,
            performanceLevel: Number(childPerformanceLevel),
            adminNote: adminNote
        };
        const response = await postRequest('/auth/update-child', token, data);
        if (response.error) {
            setShowToast({
                toastStatus: true, 
                toastMessage: response.error,
                toastAppearance: 'danger'
            });
        }
        else {
            setShowToast({
                toastStatus: true, 
                toastMessage: 'Daten erfolgreich gespeichert. 🥳',
                toastAppearance: 'success'
            });
            setHasChanged(false);
        }
        refetch();
    }

    return (
        <Card className="mt-3 mb-3">
            <Card.Header>Leistungsniveau anpassen</Card.Header>
            <Card.Body>
                <Row>

                    <Col xs={12} md={6} className="mb-3">
                        Aktueller Leistungsstand:
                    </Col>
                    <Col xs={12} md={6} className="text-end">
                        <Form.Select
                            className="text-center"
                            value={childPerformanceLevel}
                            onChange={handleSelectChange}
                        >
                            <option value={0}>Babyschwimmen</option>
                            <option value={1}>Wassergewöhnung</option>
                            <option value={2}>Anfänger</option>
                            <option value={3}>Fortgeschritten</option>
                            <option value={4}>Leistungskurs</option>
                        </Form.Select>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} className="mt-3">
                        Leistungskommentar:
                    </Col>
                    <Col xs={12}>
                        <Form.Control
                            className="mt-3"
                            as={'textarea'}
                            value={adminNote}
                            onChange={handleTextAreaChange}
                        >
                        </Form.Control>
                    </Col>
                </Row>

                {/* <Row>
                    <Col xs={6}>
                        An Kursen teilgenommen:
                    </Col>
                    <Col xs={6} className="text-end">
                        {child.enrolledCourses.filter((course) => {
                            return new Date(course.date) < new Date;
                        }).length}
                    </Col>
                </Row> */}
            </Card.Body>
            <Card.Footer className="p-3">
                <Button
                    disabled={!hasChanged}
                    onClick={handleSaveButton}
                >
                    Speichern
                </Button>
            </Card.Footer>
        </Card>
    )
}