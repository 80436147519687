export const formatDateTimeToDDMMYY = (datetimeString) => {
    const date = new Date(datetimeString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(-2);
  
    return `${day}.${month}.${year}`;
}

export const formatDateTimeToDDMMYYYY = (datetimeString) => {
    const date = new Date(datetimeString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
  
    return `${day}.${month}.${year}`;
}

export const formatDateTimeToYYYYMMDD = (datetimeString) => {
    const date = new Date(datetimeString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
  
    return `${year}-${month}-${day}`;
}

export const formatDateTimeToHHMM = (datetimeString) => {
    const date = new Date(datetimeString);
    const localHours = date.getUTCHours();
    const localMinutes = date.getUTCMinutes();
    const hours = (localHours < 10 ? "0" : "") + localHours;
    const minutes = (localMinutes < 10 ? "0" : "") + localMinutes;

    return hours + ":" + minutes;
}

export const formatDateAndTimeToISO = (date, time = '00:00') => {
    const combinedStr = `${date}T${time}:00`;
    let localDate = new Date(combinedStr);
    localDate = new Date(localDate.getTime() - (localDate.getTimezoneOffset() * 60000));
    
    return localDate.toISOString().replace('.000Z', 'Z');
}

export const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}