import '../styles/loader/loader.scss';
import { Modal, Spinner } from "react-bootstrap"

export const Loader = ({isActive, children}) => {
    
    return (
        <>
            {isActive &&
                <Modal 
                    centered 
                    show={true} 
                    animation={false}
                    contentClassName="loader-background"
                >
                    <Spinner animation="border" variant="primary" /> 
                </Modal>
            }
            {!isActive &&
                <>
                    { children }
                </>
            }
        </>
    )
}