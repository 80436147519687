import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userState } from "../../../utils/atom";
import { getRequest, postRequest } from "../../../utils/apiRequest";
import { Loader } from "../../../components/loader";
import { Button, Col, Container, Modal, Row, Stack } from "react-bootstrap";
import { ArrowLeft } from "react-bootstrap-icons";
import { UserAddress } from "./user-detail-components/userAddress";
import { UserContact } from "./user-detail-components/userContact";
import { useState } from "react";
import { UserChildren } from "./user-detail-components/userChildren";
import { UserAdminRights } from "./user-detail-components/userAdminRights";
import { UserUnpaidEnrollment } from "./user-detail-components/userUnpaidEnrollment";
import { UserWhatsappBroadcast } from "./user-detail-components/userWhatsappBroadcast";

export const User = () => {
    const { id } = useParams();
    const { token } = useRecoilValue(userState); 
    const navigate = useNavigate();

    const user = useQuery({
        queryKey: ["user-by-id"],
        queryFn: () => getRequest(`/auth/getuserbyid/${id}`, token),
    });
    const { data } = user;

    return (
        <Loader isActive={user.isLoading || user.isFetching}>
            {data && (
                <Container fluid>
                    <Row className="border-0 p-3 bg-dark-subtle border-bottom border-white mb-3">
                        <Col xs={2} className="p-0">
                            <ArrowLeft
                                size={24}
                                onClick={() => navigate(-1)}
                                style={{ cursor: "pointer" }}
                            />
                        </Col>
                        <Col
                            xs={8}
                            className="text-center d-flex align-items-center justify-content-center"
                        >
                            <h5 className="m-0">
                                {data.firstName} {data.lastName}
                            </h5>
                        </Col>
                        <Col xs={2}></Col>
                    </Row>

                    <Container>
                        <Row>
                            <Col xs={12} md={6} className="mb-3">
                                <Stack gap={3}>
                                    <UserAddress user={data} token={token} />
                                    <UserChildren user={data} token={token} />
                                    <UserWhatsappBroadcast user={data} />
                                </Stack>
                            </Col>
                            
                            <Col xs={12} md={6} className="mb-3">
                                <Stack gap={3}>
                                    <UserContact user={data} token={token} />
                                    <UserUnpaidEnrollment user={data} token={token} />
                                    <UserAdminRights user={data} token={token} />
                                </Stack>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            )}
        </Loader>
    );
};
