import { useRecoilValue, useSetRecoilState } from "recoil";
import { userState } from "../utils/atom";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getRequest, postRequest } from "../utils/apiRequest";
import { useEffect, useState } from "react";
import { Loader } from "../components/loader";
import { NewRegistrations } from "../components/newRegistrations";
import { UpcomingCourses } from "../components/user-dashboard/upcomingCourses";
import { useNavigate } from "react-router-dom";
import { YourNextSchedules } from "../components/user-dashboard/yourNextSchedules";
import { News } from "../components/dashboard/news";

export const Dashboard = () => {

    const { token } = useRecoilValue(userState);
    const navigate = useNavigate();
    const userQuery = useQuery({
        queryKey: ['userStateQuery'],
        queryFn: () => getRequest('/auth/getuser', token),
    });
    const { data } = userQuery;

    return (
        <>
            <Loader isActive={userQuery.isFetching || userQuery.isLoading}>
                {data &&
                    <Container fluid>
                        <Row className="border-0 p-3 bg-dark-subtle border-bottom border-white">
                            <Col 
                                xs={12}
                                className="text-center d-flex align-items-center justify-content-center"
                            >
                                <h5 className="m-0">Willkommen {data.firstName}</h5>
                            </Col>
                            <Col xs={2}></Col>
                        </Row>
                        
                        <Container>
                            <Row className="mt-3">
                                <Col xs={12} className="mt-3">
                                    <News isEditable={false} />
                                </Col>
                            </Row>

                            {data.children.length < 1 &&
                                <Row>
                                    <Card className="p-0 mt-3 mb-3">
                                        <Card.Body>
                                            Du hast noch keine Kinder zur Teilnahme an unseren Kursen hinzugefügt.
                                        </Card.Body>
                                        <Card.Footer className="p-3">
                                            <Button
                                                onClick={() => navigate('/add-children')}
                                            >
                                                Kursteilnehmer hinzufügen</Button>
                                        </Card.Footer>
                                    </Card>
                                </Row>
                            }    

                            {data.children.length > 0 &&
                                <Row>
                                    <Col className="p-0">
                                        <YourNextSchedules />
                                    </Col>
                                </Row>
                            }
                            
                            <Row>
                                <Col className="p-0">
                                    <UpcomingCourses />
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                }
            </Loader>
        </>
    )
}