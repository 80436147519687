import { useState } from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import { postRequest } from "../utils/apiRequest";
import { useNavigate } from "react-router-dom";
import { sha256 } from "js-sha256";
import { Loader } from "../components/loader";
import mainLogo from '../assets/logo/logo-white.png';

export const Register = () => {
    const navigate = useNavigate();
    const [error, setError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [formPage, setFormPage] = useState(1);
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        street: "",
        streetNumber: "",
        postalCode: "",
        city: "",
        phone: "",
        email: "",
        password: "",
        passwordConfirm: "",
        hasWhatsAppAllowed: false
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleCheckChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            hasWhatsAppAllowed: e.target.checked
        }));
    }

    const handleNextPage = () => {
        if (formData.password === formData.passwordConfirm) {
            setError("");
            setFormPage(2);
        } else {
            setError("Passwörter stimmen nicht überein.");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const response = await postRequest("/register", "", {
            ...formData,
            password: sha256(formData.password),
        });
        if (response.error) {
            setError(
                "Fehler beim Registrieren Ihres Accounts. Wird die E-Mail-Adresse bereits verwendet?"
            );
        }
        else {
            setIsLoading(false);
            navigate("/register-finish");
        }
    };

    return (
        <Loader isActive={isLoading}>
            <Container className="min-vh-100 d-flex flex-column align-items-center justify-content-center">
                <Row>
                    <Col xs={12}>
                        <Image 
                            className="mb-3"
                            src={mainLogo}
                            width={200}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        {/* <h1>Kiddiefy - Kursverwaltung</h1> */}
                        <p>
                            Jetzt registrieren und zur Online-Schwimmkursverwaltung
                            von Kiddiefy freischalten lassen!
                        </p>
                    </Col>
                </Row>

                <Form className="p-3 w-100" onSubmit={handleSubmit}>
                    {formPage === 1 && (
                        <Row className="w-100 justify-content-center">
                            <Col xs={12} md={6}>
                                <Form.Control
                                    className="text-start mb-3"
                                    type="email"
                                    placeholder="E-Mail"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />

                                <Form.Control
                                    className="text-start mb-3"
                                    type="password"
                                    placeholder="Passwort"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    required
                                />

                                <Form.Control
                                    className="text-start mb-3"
                                    type="password"
                                    placeholder="Passwort bestätigen"
                                    name="passwordConfirm"
                                    value={formData.passwordConfirm}
                                    onChange={handleChange}
                                    required
                                />

                                <Button className="w-100" onClick={handleNextPage}>
                                    Weiter
                                </Button>
                            </Col>
                        </Row>
                    )}

                    {formPage === 2 && (
                        <Row className="w-100 justify-content-center">
                            <Col xs={12} md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        className="text-start"
                                        type="text"
                                        placeholder="Vorname"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Control
                                        className="text-start"
                                        type="text"
                                        placeholder="Nachname"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 d-flex flex-row gap-2">
                                    <Form.Control
                                        className="text-start w-75"
                                        type="text"
                                        placeholder="Straße"
                                        name="street"
                                        value={formData.street}
                                        onChange={handleChange}
                                        required
                                    />
                                    <Form.Control
                                        className="text-start w-25"
                                        type="text"
                                        placeholder="Nr."
                                        name="streetNumber"
                                        value={formData.streetNumber}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 d-flex flex-row gap-2">
                                    <Form.Control
                                        className="text-start w-25"
                                        type="number"
                                        placeholder="PLZ"
                                        name="postalCode"
                                        value={formData.postalCode}
                                        onChange={handleChange}
                                        required
                                    />
                                    <Form.Control
                                        className="text-start w-75"
                                        type="text"
                                        placeholder="Ort"
                                        name="city"
                                        value={formData.city}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Control
                                        className="text-start"
                                        type="number"
                                        placeholder="Telefon"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Row>
                                        <Col xs={11}>
                                            <Form.Label>Ja, ich möchte zum WhatsApp-Broadcast hinzugefügt werden, um dort regelmäßig über neue Kurse und wichtige Ankündigungen informiert zu werden.</Form.Label>
                                        </Col>
                                        <Col xs={1}>
                                            <Form.Check
                                                className="text-end"
                                                name="hasWhatsappAllowed"
                                                value={formData.hasWhatsAppAllowed}
                                                onChange={handleCheckChange}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group className="text-center">
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        className="w-100 mb-3"
                                    >
                                        Registrierung absenden
                                    </Button>
                                    <Button
                                        variant="primary"
                                        className="w-100 bg-dark"
                                        onClick={() => setFormPage(1)}
                                    >
                                        Zurück
                                    </Button>
                                </Form.Group>
                            </Col>
                        </Row>
                    )}
                </Form>

                {error && <p className="text-center text-danger">{error}</p>}
            </Container>
        </Loader>
    );
};
