import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom"
import { Loader } from "../../components/loader";
import { useRecoilValue } from "recoil";
import { userState } from "../../utils/atom";
import { getRequest, postRequest } from "../../utils/apiRequest";
import { Button, Card, Col, Container, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import { formatDateTimeToDDMMYY } from "../../utils/formatDateTime";
import { useState } from "react";
import { TitleWithBackButton } from "../../components/title-with-back-button/titleWithBackButton";

export const PageUserRegistration = () => {

    const navigate = useNavigate();
    const { id } = useParams();
    const { token } = useRecoilValue(userState);
    const [isLoading, setIsLoading] = useState(false);

    const userQuery = useQuery({
        queryKey: ['userQuery'],
        queryFn: () => getRequest(`/auth/getuserbyid/${id}`, token)
    });
    const { data } = userQuery;

    const activateUser = async () => {
        setIsLoading(true);
        await getRequest(`/auth/allowregistrationbyid/${id}`, token);
        setIsLoading(false);
        navigate('/all-registrations');
    }   

    const deleteUser = async () => {
        setIsLoading(true);
        await postRequest('/auth/deleteuser', token, {id: id});
        setIsLoading(false);
        navigate('/all-registrations');
    }

    return (
        <Loader isActive={userQuery.isFetching || userQuery.isLoading || isLoading}>
            {data &&
                <Container fluid>
                    <TitleWithBackButton title={data.firstName + " " + data.lastName} />
                    <Container>
                        <Row className="justify-content-center">
                            <Col xs={12} md={6}>
                                <Card className="mt-3 p-0">
                                    <Card.Header className="p-3">
                                        {`${data.firstName} ${data.lastName}`}   
                                    </Card.Header>
                                    <Card.Body className="p-0">
                                    <ListGroup>
                                            <ListGroupItem className="border-0 border-bottom p-3">
                                                <Row>
                                                    <Col className="text-start">E-Mail:</Col>
                                                    <Col className="text-end">{data.email}</Col>
                                                </Row>
                                            </ListGroupItem>
                                            <ListGroupItem className="border-0 border-bottom p-3">
                                                <Row>
                                                    <Col className="text-start">Telefon:</Col>
                                                    <Col className="text-end">{data.phone}</Col>
                                                </Row>
                                            </ListGroupItem>
                                            <ListGroupItem className="border-0 border-bottom p-3">
                                                <Row>
                                                    <Col className="text-start">Straße:</Col>
                                                    <Col className="text-end">{`${data.street} ${data.streetNumber}`}</Col>
                                                </Row>
                                            </ListGroupItem>
                                            <ListGroupItem className="border-0 border-bottom p-3">
                                                <Row>
                                                    <Col className="text-start">Ort:</Col>
                                                    <Col className="text-end">{`${data.postalCode} ${data.city}`}</Col>
                                                </Row>
                                            </ListGroupItem>
                                            <ListGroupItem className="border-0 border-bottom p-3">
                                                <Row>
                                                    <Col className="text-start">Registriert am:</Col>
                                                    <Col className="text-end">{formatDateTimeToDDMMYY(data.createdAt)}</Col>
                                                </Row>
                                            </ListGroupItem>
                                            <ListGroupItem className="border-0 border-bottom p-3">
                                                <Row>
                                                    <Col className="text-start">Whatsapp-Broadcast:</Col>
                                                    <Col className="text-end">{data.hasWhatsAppAllowed ? "Zugestimmt" : "Abgelehnt"}</Col>
                                                </Row>
                                            </ListGroupItem>
                                    </ListGroup>
                                    </Card.Body>
                                    <Card.Footer>
                                        <Row className="mt-3 mb-3 text-center">
                                            <Col xs={6}>
                                                <Button onClick={activateUser}>Nutzer freigeben</Button>
                                            </Col>
                                            <Col xs={6}>
                                                <Button onClick={deleteUser} className="btn-danger">Nutzer ablehnen</Button>
                                            </Col>
                                        </Row>  
                                    </Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            }
        </Loader>
    )
}