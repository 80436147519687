import { getRequest } from "../../utils/apiRequest";
import { useQuery } from "@tanstack/react-query";
import { useRecoilValue } from "recoil";
import { userState } from "../../utils/atom";
import { Loader } from "../../components/loader";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import {
    formatDateTimeToDDMMYY,
    formatDateTimeToHHMM,
} from "../../utils/formatDateTime";
import { useNavigate } from "react-router-dom";
import {
    ArrowLeft,
    ChevronDoubleLeft,
    ChevronDoubleRight,
    PeopleFill,
} from "react-bootstrap-icons";

export const AllActiveCoursesUser = () => {
    const COURSES_PER_PAGE = 5;
    const { token } = useRecoilValue(userState);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState([]);
    const [courses, setCourses] = useState([]);

    const navigate = useNavigate();

    const allActiveCourses = useQuery({
        queryKey: ["allactivecoursesuser"],
        queryFn: () =>
            getRequest(
                `/auth/course/upcoming-user?page=${page}&amount=${COURSES_PER_PAGE}`,
                token
            ),
    });
    let { data } = allActiveCourses;

    const handleNavigate = (courseId) => {
        sessionStorage.setItem("page", page);
        navigate(`/course/${courseId}`);
    };

    const incrementPage = () => {
        setPage((prev) => {
            return prev + 1;
        });
    };

    const decrementPage = () => {
        setPage((prev) => {
            return prev - 1;
        });
    };

    useEffect(() => {
        if (data) {
            const pagesAmount = Math.ceil(data.total / COURSES_PER_PAGE);
            setTotalPages(pagesAmount);
            setCourses(data.courses);
        }
    }, [data]);

    useEffect(() => {
        allActiveCourses.refetch();
    }, [page]);

    useEffect(() => {
        const page = sessionStorage.getItem("page");
        if (page) {
            setPage(Number(page));
            sessionStorage.removeItem("page");
        }
    }, []);

    return (
        <Loader
            isActive={allActiveCourses.isFetching || allActiveCourses.isLoading}
        >
            {data && (
                <Container fluid>
                    <Row className="border-0 p-3 bg-dark-subtle border-bottom border-white mb-3">
                        <Col xs={2} className="p-0">
                            <ArrowLeft
                                size={24}
                                onClick={() => navigate(-1)}
                                style={{ cursor: "pointer" }}
                            />
                        </Col>
                        <Col
                            xs={8}
                            className="text-center d-flex align-items-center justify-content-center"
                        >
                            <h5 className="m-0">Alle Kurse</h5>
                        </Col>
                        <Col xs={2}></Col>
                    </Row>

                    {data.courses.length < 1 ? (
                        <Row className="mt-3">
                            <Col className="text-center">
                                <p>
                                    In nächster Zeit finden keine weiteren Kurse
                                    statt.
                                </p>
                            </Col>
                        </Row>
                    ) : (
                        <>
                            <Container>
                                <Row className="mt-3">
                                    {courses.map((course) => {
                                        return (
                                            <Card
                                                className={`mt-3 mb-3 p-0 ${
                                                    course.performanceLevel ===
                                                    4
                                                        ? "border-warning"
                                                        : ""
                                                }`}
                                                key={course.id}
                                                id={course.id}
                                            >
                                                <Card.Header className="p-3">
                                                    <Row>
                                                        <Col xs={8}>
                                                            <h5 className="m-0">
                                                                {course.title}
                                                            </h5>
                                                        </Col>
                                                        {course.performanceLevel ===
                                                            4 && (
                                                            <Col
                                                                className="text-end"
                                                                xs={4}
                                                            >
                                                                👑
                                                            </Col>
                                                        )}
                                                    </Row>
                                                </Card.Header>
                                                <Card.Body className="p-0">
                                                    <Row className="m-0 p-3 border-bottom">
                                                        <Col
                                                            className="p-0"
                                                            xs={3}
                                                        >
                                                            Datum:
                                                        </Col>
                                                        <Col
                                                            className="text-end"
                                                            xs={9}
                                                        >
                                                            {formatDateTimeToDDMMYY(
                                                                course.date
                                                            )}
                                                        </Col>
                                                    </Row>
                                                    <Row className="m-0 p-3 border-bottom">
                                                        <Col
                                                            className="p-0"
                                                            xs={3}
                                                        >
                                                            Uhrzeit:
                                                        </Col>
                                                        <Col
                                                            className="text-end"
                                                            xs={9}
                                                        >
                                                            {formatDateTimeToHHMM(
                                                                course.date
                                                            )}{" "}
                                                            Uhr
                                                        </Col>
                                                    </Row>
                                                    <Row className="m-0 p-3 border-bottom">
                                                        <Col
                                                            className="p-0"
                                                            xs={3}
                                                        >
                                                            Ort:
                                                        </Col>
                                                        <Col
                                                            className="text-end"
                                                            xs={9}
                                                        >
                                                            {
                                                                course.locationName
                                                            }
                                                            ,{" "}
                                                            {
                                                                course.locationCity
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Row className="m-0 p-3 border-bottom">
                                                        <Col
                                                            className="p-0"
                                                            xs={8}
                                                        >
                                                            Kosten:
                                                        </Col>
                                                        <Col
                                                            className="text-end"
                                                            xs={4}
                                                        >
                                                            {course.cost} €
                                                        </Col>
                                                    </Row>
                                                    <Row className="m-0 p-3">
                                                        <Col
                                                            className="p-0"
                                                            xs={8}
                                                        >
                                                            Teilnehmer:
                                                        </Col>
                                                        <Col
                                                            className="text-end"
                                                            xs={4}
                                                        >
                                                            {
                                                                course.participants
                                                            }{" "}
                                                            |{" "}
                                                            {
                                                                course.maxParticipants
                                                            }{" "}
                                                            <PeopleFill />
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                                <Card.Footer className="p-3">
                                                    <Row>
                                                        <Col xs={6}>
                                                            <Button
                                                                className="bg-success border-0 w-100"
                                                                onClick={() =>
                                                                    handleNavigate(
                                                                        course.id
                                                                    )
                                                                }
                                                            >
                                                                Kursdetails
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </Card.Footer>
                                            </Card>
                                        );
                                    })}
                                </Row>
                                {courses.length > 0 && (
                                    <Row>
                                        <Col
                                            xs={2}
                                            className="d-flex align-items-center justify-content-start"
                                        >
                                            {page > 1 && (
                                                <ChevronDoubleLeft
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                        setPage((prev) => {
                                                            return prev - 1;
                                                        })
                                                    }
                                                />
                                            )}
                                        </Col>
                                        <Col xs={8} className="mb-3 mt-3">
                                            <Form.Select
                                                value={page}
                                                onChange={(e) =>
                                                    setPage(
                                                        parseInt(e.target.value)
                                                    )
                                                }
                                            >
                                                {Array.from(
                                                    { length: totalPages },
                                                    (_, index) => (
                                                        <option
                                                            className="text-center"
                                                            key={index + 1}
                                                            value={index + 1}
                                                        >
                                                            {`Seite ${
                                                                index + 1
                                                            } von ${totalPages}`}
                                                        </option>
                                                    )
                                                )}
                                            </Form.Select>
                                        </Col>
                                        <Col
                                            xs={2}
                                            className="d-flex align-items-center justify-content-end"
                                        >
                                            {page < totalPages && (
                                                <ChevronDoubleRight
                                                    style={{
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                        setPage((prev) => {
                                                            return prev + 1;
                                                        })
                                                    }
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                )}
                            </Container>
                        </>
                    )}
                </Container>
            )}
        </Loader>
    );
};
