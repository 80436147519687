import { useState } from "react"
import { Button, Card, Col, Row } from "react-bootstrap"
import { addDays, formatDateTimeToDDMMYYYY } from "../../../../../utils/formatDateTime";
import { SubPaymentModal } from "./subPaymentModal";

export const ChildDetailPerformanceSubscription = ({ child, token, refetch }) => {

    const calculateHasActiveSub = (subPaymentDate) => {
        if (!subPaymentDate) {
            return false;
        }
        const dateDifference = (new Date() - new Date(subPaymentDate)) / (1000 * 60 * 60 * 24);
        return dateDifference <= 30;
    }

    const [hasActiveSub, setHasActiveSub] = useState(calculateHasActiveSub(child.subPaymentDate));
    const [showSubPaymentModal, setShowSubPaymentModal] = useState(false);

    return (
        <>
            <Card className={`mt-3 mb-3`}>
                <Card.Header>
                    Leistungskurs-Abonnement
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col>
                            {child.subPaymentDate === null &&
                                <p className="m-0">Bisher wurden keine Abonnement-Zahlungen erfasst.</p>
                            }

                            {(child.subPaymentDate && hasActiveSub) &&
                                <>  
                                    <Row>
                                        <Col xs={6} className="text-success">Letzter Zahlungseingang:</Col>
                                        <Col xs={6} className="text-success text-end">{formatDateTimeToDDMMYYYY(child.subPaymentDate)}</Col>
                                    </Row>

                                    <Row>
                                        <Col xs={6} className="">Abonnement aktiv seit:</Col>
                                        <Col xs={6} className="text-end">{formatDateTimeToDDMMYYYY(child.subActiveSince)}</Col>
                                    </Row>

                                    <Row>
                                        <Col xs={6}>Abonnement gültig bis:</Col>
                                        <Col xs={6} className="text-end">
                                            {formatDateTimeToDDMMYYYY(addDays(new Date(child.subActiveSince), 30))}
                                        </Col>
                                    </Row>
                                </>
                            }

                            {(child.subPaymentDate && !hasActiveSub) &&
                                <>
                                    <Row>
                                        <Col xs={6} className="text-danger">Letzter Zahlungseingang:</Col>
                                        <Col xs={6} className="text-danger text-end">{formatDateTimeToDDMMYYYY(child.subPaymentDate)}</Col>
                                    </Row>

                                    <Row>
                                        <Col xs={6} className="">Abonnement aktiv seit:</Col>
                                        <Col xs={6} className="text-end">{formatDateTimeToDDMMYYYY(child.subActiveSince)}</Col>
                                    </Row>

                                    <Row>
                                        <Col xs={6}>Abonnement unbezahlt seit:</Col>
                                        <Col xs={6} className="text-end">
                                            {formatDateTimeToDDMMYYYY(addDays(new Date(child.subPaymentDate), 30))}
                                        </Col>
                                    </Row>
                                </>
                            }
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer className="p-3">
                    <Button onClick={() => setShowSubPaymentModal(true)} >Abonnementzahlung erfassen</Button>
                </Card.Footer>
            </Card>

            <SubPaymentModal 
                child={child}
                showSubPaymentModal={showSubPaymentModal}
                setShowSubPaymentModal={setShowSubPaymentModal}
                token={token}
                refetch={refetch}
            />
        </>
    )
}