import { useState } from "react"
import { Form, Modal } from "react-bootstrap"
import { formatDateAndTimeToISO } from "../../../../../utils/formatDateTime";
import { postRequest } from "../../../../../utils/apiRequest";
import { useOutletContext } from "react-router-dom";

export const SubPaymentModal = ({ child, showSubPaymentModal, setShowSubPaymentModal, token, refetch }) => {

    const [formData, setFormData] = useState({
        subPaymentDate: '',
        subPaymentAmount: '',
        subActiveSince: ''
    });
    
    const [showToast, setShowToast] = useOutletContext();

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = {
            id: child.id,
            subPaymentDate: formatDateAndTimeToISO(formData.subPaymentDate),
            subActiveSince: formatDateAndTimeToISO(formData.subActiveSince),
            subPaymentAmount: formData.subPaymentAmount
        }
        const response = await postRequest('/auth/update-child', token, data);
        if (response.error) {
            setShowToast({
                toastStatus: true, 
                toastMessage: response.error,
                toastAppearance: 'danger'
            });
        }
        else {
            setShowToast({
                toastStatus: true, 
                toastMessage: 'Abonnement erfolgreich verlängert! 🥳',
                toastAppearance: 'success'
            });
        }
        await refetch();
        setShowSubPaymentModal(false);
    }

    return (
        <Modal 
            show={showSubPaymentModal} 
            onHide={() => setShowSubPaymentModal(false)}
            centered
        >
            <Modal.Header closeButton>Abonnementzahlung erfassen</Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Label>{"Abonnement-Gebühr (in €)"}</Form.Label>
                        <Form.Control
                            type="number"
                            name="subPaymentAmount"
                            value={formData.subPaymentAmount}
                            onChange={handleChange}
                            required
                        ></Form.Control>
                    </Form.Group>

                    <Form.Group className="mt-3">
                        <Form.Label>{"Abonnement starten / verlängern ab"}</Form.Label>
                        <Form.Control
                            type="date"
                            name="subActiveSince"
                            value={formData.subActiveSince}
                            onChange={handleChange}
                            required
                        ></Form.Control>
                    </Form.Group>

                    <Form.Group className="mt-3">
                        <Form.Label>{"Zahlungseingang"}</Form.Label>
                        <Form.Control
                            type="date"
                            name="subPaymentDate"
                            value={formData.subPaymentDate}
                            onChange={handleChange}
                            required
                        ></Form.Control>
                    </Form.Group>

                    <Form.Control 
                        className="mt-3 btn btn-primary"
                        type="submit"
                        value={"Zahlung speichern"}
                    >

                    </Form.Control>
                </Form>
            </Modal.Body>
        </Modal>
    )
}