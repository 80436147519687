import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import { Loader } from "../../../components/loader"
import { TitleWithBackButton } from "../../../components/title-with-back-button/titleWithBackButton";
import { getRequest, postRequest } from "../../../utils/apiRequest";
import { useQuery } from "@tanstack/react-query";
import { useRecoilValue } from "recoil";
import { userState } from "../../../utils/atom";
import { useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";

export const NewNews = () => {

    const { token } = useRecoilValue(userState);
    const [showToast, setShowToast] = useOutletContext();

    const newsQuery = useQuery({
        queryKey: ['get-news'],
        queryFn: () => getRequest(`/auth/get-news`, token)
    });
    const { data } = newsQuery;

    const [formData, setFormData] = useState({
        title: 'Beispielnews',
        text: 'Hier siehst du die Vorschau deiner News.',
        color: 'success',
        isActive: false
    });

    useEffect(() => {
        if (data) {
            setFormData((prev) => ({
                ...prev,
                title: data[0].title,
                text: data[0].text,
                color: data[0].color
            }));
        }
    }, [data]);

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const response = await postRequest('/auth/new-news', token, formData);
        if (response.error) {
            setShowToast({
                toastStatus: true, 
                toastMessage: response.error,
                toastAppearance: 'danger'
            });
        }
        else {
            setShowToast({
                toastStatus: true,
                toastMessage: 'Deine Einstellungen wurden aktualisiert. 🥳',
                toastAppearance: 'success'
            });
        }
        newsQuery.refetch();
    }

    const toggleNewsState = async (id) => {
        const response = await postRequest('/auth/toggle-news-active-state', token, {id: id});
        if (response.error) {
            setShowToast({
                toastStatus: true, 
                toastMessage: response.error,
                toastAppearance: 'danger'
            });
        }
        else {
            setShowToast({
                toastStatus: true,
                toastMessage: 'Deine Einstellungen wurden aktualisiert. 🥳',
                toastAppearance: 'success'
            });
        }
        newsQuery.refetch();
    }

    return (
        <Loader isActive={newsQuery.isLoading || newsQuery.isFetching}>
            {data &&
                <Container fluid>
                    <TitleWithBackButton title={'News verwalten'} />
                    <Container>
                        <Row>
                            <Col>
                                <Alert variant={formData.color}>
                                    <Alert.Heading>{formData.title}</Alert.Heading>
                                    <p className="m-0">{formData.text}</p>
                                </Alert>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12}>
                                {data[0].isActive 
                                ?
                                    <p className="m-0 text-success">Diese News ist derzeit aktiv.</p>

                                :   
                                    <p className="m-0 text-danger">Diese News ist derzeit inaktiv.</p>
                                }
                            </Col>

                            <Col xs={12} className="mt-3 mb-3">
                                <Button onClick={() => toggleNewsState(data[0].id)} >
                                    {data[0].isActive ? 'News deaktivieren' : 'News aktivieren'}
                                </Button>
                            </Col>
                        </Row>
                        <hr></hr>

                        <Form className="mt-3" onSubmit={handleFormSubmit}>
                            <Row>
                                <Col xs={12}>
                                    <h5>Neue News erstellen</h5>
                                </Col>
                            </Row>

                            <Form.Group className="mt-3">
                                <Form.Label>Titel</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="title"
                                    value={formData.title}
                                    onChange={handleFormChange}
                                    required
                                ></Form.Control>
                            </Form.Group>

                            <Form.Group className="mt-3">
                                <Form.Label>Text</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    type="text"
                                    name="text"
                                    value={formData.text}
                                    onChange={handleFormChange}
                                    required
                                    style={{maxHeight: '200px'}}
                                ></Form.Control>
                            </Form.Group>

                            <Form.Group className="mt-3">
                                <Form.Label>Farbe</Form.Label>
                                <Form.Select
                                    type="text"
                                    name="color"
                                    value={formData.color}
                                    onChange={handleFormChange}
                                    required
                                >
                                    <option value={'success'}>Grün</option>
                                    <option value={'warning'}>Gelb</option>
                                    <option value={'danger'}>Rot</option>
                                    <option value={'info'}>Blau</option>
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className='d-flex justify-content-between mt-3'>
                                <Form.Label>Direkt veröffentlichen?</Form.Label>
                                <Form.Check
                                    type='switch'
                                    checked={formData.isActive}
                                    onChange={() => setFormData((prev) => ({...prev, isActive: !prev.isActive}))}
                                />
                            </Form.Group>

                            <Form.Control
                                type="submit"
                                value={'News speichern'}
                                className="btn btn-primary mt-3"
                            >
                            </Form.Control>

                        </Form>
                    </Container>
                </Container>
            }
        </Loader>
    )
}