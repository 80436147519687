import { Button, Card, Col, Form, Row } from "react-bootstrap"
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { LockFill, UnlockFill } from "react-bootstrap-icons";
import { postRequest } from "../../../../utils/apiRequest";
import { Loader } from "../../../../components/loader";
import { formatDateTimeToYYYYMMDD } from "../../../../utils/formatDateTime";

export const ChildDetailGeneralUser = ({ child, token }) => {

    const [isEditable, setIsEditable] = useState(false);
    const [childData, setChildData] = useState(child);
    const [showToast, setShowToast] = useOutletContext();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setChildData((prevState) => ({
            ...prevState,
            birthdate: formatDateTimeToYYYYMMDD(prevState.birthdate)
        }));
    }, [child]);

    const handleChange = (event) => {
        const {name, value} = event.target;
        setChildData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        const data = {
            id: child.id,
            firstName: childData.firstName,
            lastName: childData.lastName,
            birthdate: childData.birthdate,
            note: childData.note
        }
        const response = await postRequest('/auth/update-child', token, data);
        if (response.error) {
            setShowToast({
                toastStatus: true, 
                toastMessage: response.error,
                toastAppearance: 'danger'
            });
        }
        else {
            setShowToast({
                toastStatus: true, 
                toastMessage: 'Nutzerdaten aktualisiert. 🥳',
                toastAppearance: 'success'
            });
            setIsEditable((prev) => !prev);
        }
        setIsLoading(false);
    }

    return (
        <Loader isActive={isLoading}>
            <Card className="mt-3 mb-3">
                <Card.Header>
                    <Row>
                        <Col xs={6}>
                            Allgemein
                        </Col>
                        <Col xs={6} className="text-end">
                            {!isEditable 
                                ? <LockFill onClick={() => setIsEditable((prev) => !prev)} style={{cursor: 'pointer'}} /> 
                                : <UnlockFill onClick={() => setIsEditable((prev) => !prev)} style={{cursor: 'pointer'}} />
                            }
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col xs={4} className="mb-3">Vorname:</Col>
                        <Col xs={8} className="text-end">
                            <Form.Control
                                className="mb-3"
                                type="text"
                                disabled={!isEditable}
                                value={`${childData.firstName}`}
                                name="firstName"
                                onChange={handleChange}
                            ></Form.Control>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4} className="mb-3">Nachname:</Col>
                        <Col xs={8} className="text-end">
                            <Form.Control
                                className="mb-3"
                                type="text"
                                disabled={!isEditable}
                                value={`${childData.lastName}`}
                                name="lastName"
                                onChange={handleChange}
                            ></Form.Control>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4} className="mb-3" >Geburtsdatum:</Col>
                        <Col xs={8} className="text-end">
                            <Form.Control
                                className="mb-3"
                                type="date"
                                disabled={!isEditable}
                                value={childData.birthdate}
                                name="birthdate"
                                onChange={handleChange}
                            ></Form.Control>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4}>Hinweis:</Col>
                        <Col xs={8} className="text-end">
                            <Form.Control
                                as={'textarea'}
                                value={childData.note}
                                disabled={!isEditable}
                                onChange={handleChange}
                                name="note"
                            >
                            </Form.Control>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer className="p-3">
                    <Button
                        disabled={!isEditable}
                        onClick={handleSubmit}
                    >
                        Änderungen speichern
                    </Button>
                </Card.Footer>
            </Card>
        </Loader>
    )
}