import { Button, Card, Col, Container, Form, Row, Toast } from "react-bootstrap"
import { Loader } from "../../components/loader"
import { useQuery } from "@tanstack/react-query";
import { getRequest, postRequest } from "../../utils/apiRequest";
import { useRecoilValue } from "recoil";
import { userState } from "../../utils/atom";
import { useEffect, useState } from "react";
import { formatDateAndTimeToISO } from "../../utils/formatDateTime";
import { v4 } from 'uuid';
import { useNavigate, useOutletContext } from "react-router-dom";
import { ArrowLeft } from "react-bootstrap-icons";

export const NewCourse = () => {

    const { token } = useRecoilValue(userState);
    const [showToast, setShowToast] = useOutletContext();
    const initalData = {
        id: '',
        courseTitle: '',
        courseDate: '',
        courseTime: '',
        courseLocationName: '',
        courseLocationPostalCode: '',
        courseLocationCity: '',
        courseLocationStreet: '',
        courseLocationStreetNumber: '',
        courseCost: '',
        courseMaxParticipants: 0,
        courseInfo: '',
        courseTeacher: '',
        isPerformanceCourse: false,
        performanceLevel: 0
    }
    const [courseData, setCourseData] = useState(initalData);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
 
    const courseTemplatesQuery = useQuery({
        queryKey: ['newRegistrationsQuery'],
        queryFn: () => getRequest('/auth/course-templates', token)
    });
    const { data } = courseTemplatesQuery;

    const handleChange = (e) => {
        setCourseData((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        });
    }

    const handleSelectChange = (e) => {
        if (e.target.value == -1) {
            setCourseData(initalData);
            return;
        }
        setCourseData((prev) => {
            return {
                ...prev,
                id: data[e.target.value].id,
                courseTitle: data[e.target.value].templateName,
                courseMaxParticipants: Number(data[e.target.value].maxParticipants),
                courseCost: Number(data[e.target.value].cost),
                courseLocationName: data[e.target.value].locationName,
                courseLocationStreet: data[e.target.value].locationStreet,
                courseLocationStreetNumber: data[e.target.value].locationStreetNumber,
                courseLocationPostalCode: data[e.target.value].locationPostalCode,
                courseLocationCity: data[e.target.value].locationCity,
                performanceLevel: data[e.target.value].performanceLevel,
                courseInfo: data[e.target.value].courseInfo,
                courseTeacher: data[e.target.value].courseTeacher,
                isPerformanceCourse: data[e.target.value].performanceLevel === 4 // Course will be a performance course when "Leistungskurs" is selected.
            }
        });
    }

    const handleSaveAsTemplate = async () => {
        setIsLoading(true);
        const data = {
            id: courseData.id ? courseData.id : v4(),
            title: courseData.courseTitle ? courseData.courseTitle : '',
            templateName: courseData.courseTitle ? courseData.courseTitle : '',
            maxParticipants: Number(courseData.courseMaxParticipants),
            cost: courseData.courseCost ? courseData.courseCost.toString() : '',
            locationName: courseData.courseLocationName ? courseData.courseLocationName : '',
            locationStreet: courseData.courseLocationStreet ? courseData.courseLocationStreet : '',
            locationStreetNumber: courseData.courseLocationStreetNumber ? courseData.courseLocationStreetNumber : '',
            locationPostalCode: courseData.courseLocationPostalCode ? courseData.courseLocationPostalCode : '',
            locationCity: courseData.courseLocationCity ? courseData.courseLocationCity : '',
            performanceLevel: Number(courseData.performanceLevel),
            courseInfo: courseData.courseInfo ? courseData.courseInfo : '',
            courseTeacher: courseData.courseTeacher ? courseData.courseTeacher : '',
            isPerformanceCourse: courseData.performanceLevel === 4
        }
        const response = await postRequest('/auth/course-template/new', token, data);
        if (response.error) {
            setSuccess('');
            setError(response.error);
        }
        else {
            setError('');
            setSuccess('Kursvorlage erfolgreich gespeichert.');
        }
        courseTemplatesQuery.refetch();
        setIsLoading(false);
    }

    const handleSaveCourse = async () => {
        if (Object.entries(courseData).some(([key, value]) => {
            if (key === 'performanceLevel' || key === 'courseInfo' || key === 'id') {
                return false;
            }
            return value === '' || value === 0;
        })) {
            setSuccess('');
            setError('Alle Felder müssen ausgefüllt sein, um einen Kurs anzulegen.');
            return;
        }
        setIsLoading(true);
        const data = {
            id: v4(),
            title: courseData.courseTitle,
            maxParticipants: Number(courseData.courseMaxParticipants),
            cost: courseData.courseCost.toString(),
            locationName: courseData.courseLocationName,
            locationStreet: courseData.courseLocationStreet,
            locationStreetNumber: courseData.courseLocationStreetNumber,
            locationPostalCode: courseData.courseLocationPostalCode,
            locationCity: courseData.courseLocationCity,
            date: formatDateAndTimeToISO(courseData.courseDate, courseData.courseTime),
            performanceLevel: Number(courseData.performanceLevel),
            courseInfo: courseData.courseInfo,
            courseTeacher: courseData.courseTeacher,
            isPerformanceCourse: courseData.performanceLevel === 4
        }
        const response = await postRequest('/auth/course/new', token, data);
        if (response.error) {
            setError(response.error);
            setSuccess('');
            setIsLoading(false);
        }
        else {
            setShowToast({
                toastStatus: true, 
                toastMessage: 'Kurs erfolgreich gespeichert. 🥳',
                toastAppearance: 'success'
            });
            navigate('/');
        }     
    }

    useEffect(() => {
        setCourseData(initalData);
    }, []);

    return (
        <Loader isActive={courseTemplatesQuery.isFetching || courseTemplatesQuery.isLoading || isLoading}>
            {data &&
                <Container fluid>
                    <Row className="border-0 p-3 bg-dark-subtle border-bottom border-white mb-3">
                        <Col xs={2} className="p-0">
                            <ArrowLeft 
                                size={24}
                                onClick={() => navigate(-1)}
                                style={{cursor: 'pointer'}}
                            />
                        </Col>
                        <Col 
                            xs={8}
                            className="text-center d-flex align-items-center justify-content-center"
                        >
                            <h5 className="m-0">{'Neuen Kurs planen 📝'}</h5>
                        </Col>
                        <Col xs={2}>
                            
                        </Col>
                    </Row>

                    <Container>
                        <Row>
                            <Col className="text-center mt-3 mb-3">
                                {data.length > 0
                                ?   
                                    <>
                                        <Form.Select 
                                            className="text-center" 
                                            size="md"
                                            onChange={handleSelectChange}
                                        >
                                            <option value={-1}>Neue Vorlage erstellen</option>
                                            {data.map((template, index) => {
                                                return (
                                                    <option
                                                        key={template.id}
                                                        value={index}
                                                    >
                                                        {template.templateName}
                                                    </option>
                                                )
                                            })}
                                        </Form.Select>
                                    </>
                                :
                                    <p>
                                        Du hast noch keine Kursvorlagen.
                                        Erstelle einen Kurs und speichere ihn als Vorlage ab,
                                        um schnell neue Kurse zu erstellen. Klicke dazu auf 
                                        "Als Vorlage speichern" bei einem deiner geplanten Kurse.
                                    </p>
                                }
                            </Col>
                        </Row>

                        <Row className="mt-3 mb-3">
                            <Col className="mb-3">
                                <Form>
                                    <Card className="mb-3">
                                        <Card.Header>Allgemein</Card.Header>
                                        <Card.Body>
                                            <Form.Group className="mb-3">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Kurstitel"
                                                    value={courseData.courseTitle}
                                                    name="courseTitle"
                                                    onChange={(e) => handleChange(e)}
                                                >
                                                </Form.Control>
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Row>
                                                    <Col xs={9} className="m-0 d-flex align-items-center">
                                                        <Form.Label>Leistungsstand:</Form.Label>
                                                    </Col>

                                                    <Col>
                                                        <Form.Select
                                                            type="text"
                                                            placeholder="Kurstitel"
                                                            value={courseData.performanceLevel}
                                                            name="performanceLevel"
                                                            onChange={(e) => handleChange(e)}
                                                        >
                                                            <option value={0}>Babyschwimmen</option>
                                                            <option value={1}>Wassergewöhnung</option>
                                                            <option value={2}>Anfänger</option>
                                                            <option value={3}>Fortgeschritten</option>
                                                            <option value={35}>Gemischt</option>
                                                            <option value={4}>Leistungskurs</option>
                                                        </Form.Select>
                                                    </Col>
                                                </Row>
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Row>
                                                    <Col xs={9} className="m-0 d-flex align-items-center">
                                                        <Form.Label className="m-0">Maximale Teilnehmer:</Form.Label>
                                                    </Col>
                                                    <Col xs={3}>
                                                        <Form.Control
                                                            className="text-center"
                                                            type="number"
                                                            value={courseData.courseMaxParticipants}
                                                            name="courseMaxParticipants"
                                                            onChange={(e) => handleChange(e)}
                                                        >
                                                        </Form.Control>
                                                    </Col>
                                                </Row>
                                            </Form.Group>

                                            <Form.Group>
                                                <Row>
                                                    <Col xs={9} className="m-0 d-flex align-items-center">
                                                        <Form.Label className="m-0">Kurskosten:</Form.Label>
                                                    </Col>
                                                    <Col xs={3}>
                                                        <Form.Control
                                                            className="text-center"
                                                            type="number"
                                                            value={courseData.courseCost}
                                                            name="courseCost"
                                                            onChange={(e) => handleChange(e)}
                                                            placeholder="€"
                                                        >
                                                        </Form.Control>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Card.Body>
                                    </Card>

                                    <Row>
                                        <Col>
                                            <Card className="mt-3 mb-3">
                                                <Card.Header>Ort</Card.Header>
                                                <Card.Body>
                                                    <Form.Group className="mb-3">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Veranstaltungsort"
                                                            value={courseData.courseLocationName}
                                                            name="courseLocationName"
                                                            onChange={(e) => handleChange(e)}
                                                        >
                                                        </Form.Control>
                                                    </Form.Group>

                                                    <Form.Group className="mb-3">
                                                        <Row>
                                                            <Col xs={9} className="m-0 d-flex align-items-center">
                                                                <Form.Control
                                                                    type="text"
                                                                    name="courseLocationStreet"
                                                                    placeholder="Straße"
                                                                    value={courseData.courseLocationStreet}
                                                                    onChange={handleChange}
                                                                >
                                                                </Form.Control>
                                                            </Col>
                                                            <Col xs={3}>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Nr."
                                                                    value={courseData.courseLocationStreetNumber}
                                                                    name="courseLocationStreetNumber"
                                                                    onChange={(e) => handleChange(e)}
                                                                >
                                                                </Form.Control>
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>

                                                    <Form.Group>
                                                        <Row>
                                                            <Col xs={4} className="m-0 d-flex align-items-center">
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="PLZ"
                                                                    value={courseData.courseLocationPostalCode}
                                                                    name="courseLocationPostalCode"
                                                                    onChange={handleChange}
                                                                >
                                                                </Form.Control>
                                                            </Col>
                                                            <Col xs={8}>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Ort"
                                                                    value={courseData.courseLocationCity}
                                                                    name="courseLocationCity"
                                                                    onChange={handleChange}  
                                                                >
                                                                </Form.Control>
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                    
                                    <Row>
                                        <Col className="mt-3 mb-3">
                                            <Card>
                                                <Card.Header>Infos</Card.Header>
                                                <Card.Body>
                                                    <Form.Group className="mb-3">
                                                        <Row>
                                                            <Col xs={6}>
                                                                <Form.Label>Lehrer</Form.Label>
                                                            </Col>
                                                            <Col xs={6} className="m-0 d-flex align-items-center">
                                                                <Form.Control
                                                                    name="courseTeacher"
                                                                    type="text"
                                                                    value={courseData.courseTeacher}
                                                                    onChange={handleChange}
                                                                >
                                                                </Form.Control>
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>

                                                    <Form.Group>
                                                        <Row>
                                                            <Col xs={6}>
                                                                <Form.Label>Weitere Informationen</Form.Label>
                                                            </Col>
                                                            <Col xs={6} className="m-0 d-flex align-items-center">
                                                                <Form.Control
                                                                    name="courseInfo"
                                                                    as="textarea"
                                                                    value={courseData.courseInfo}
                                                                    onChange={handleChange}
                                                                    style={{height: '150px', maxHeight: '300px'}}
                                                                >
                                                                </Form.Control>
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>
                                                </Card.Body>
                                            </Card>        
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className="mt-3">
                                            <Card>
                                                <Card.Header>Datum & Uhrzeit</Card.Header>
                                                <Card.Body>
                                                    <Form.Group className="mb-3">
                                                        <Row>
                                                            <Col xs={6}>
                                                                <Form.Label>Datum</Form.Label>
                                                            </Col>
                                                            <Col xs={6} className="m-0 d-flex align-items-center">
                                                                <Form.Control
                                                                    name="courseDate"
                                                                    type="date"
                                                                    value={courseData.courseDate}
                                                                    onChange={handleChange}
                                                                >
                                                                </Form.Control>
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>

                                                    <Form.Group>
                                                        <Row>
                                                            <Col xs={6}>
                                                                <Form.Label>Uhrzeit</Form.Label>
                                                            </Col>
                                                            <Col xs={6} className="m-0 d-flex align-items-center">
                                                                <Form.Control
                                                                    name="courseTime"
                                                                    type="time"
                                                                    value={courseData.courseTime}
                                                                    onChange={handleChange}
                                                                >
                                                                </Form.Control>
                                                            </Col>
                                                        </Row>
                                                    </Form.Group>
                                                </Card.Body>
                                            </Card>        
                                        </Col>
                                    </Row>
                                    
                                </Form>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={6}>
                                <Button 
                                    className="btn-warning w-100" 
                                    onClick={handleSaveAsTemplate}
                                >
                                    Als Vorlage speichern
                                </Button>
                            </Col>
                            <Col xs={6}>
                                <Button 
                                    className="w-100 h-100"
                                    onClick={handleSaveCourse}
                                >
                                    Kurs anlegen
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {error &&
                                    <p className="text-danger text-center">{error}</p>
                                }
                                {success &&
                                    <p className="text-success text-center">{success}</p>
                                }
                            </Col>
                        </Row>
                    </Container>
                </Container>
            }
        </Loader>
    )
}