import { getRequest, patchRequest } from "../../../utils/apiRequest"
import { useQuery } from "@tanstack/react-query"
import { useRecoilValue } from "recoil";
import { userState } from "../../../utils/atom";
import { Loader } from "../../../components/loader";
import { Button, Card, Col, Container, Pagination, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { formatDateTimeToDDMMYY, formatDateTimeToHHMM } from "../../../utils/formatDateTime";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ArrowLeft, ChevronDoubleLeft, ChevronDoubleRight } from "react-bootstrap-icons";

export const AllPlannedCourses = () => {

    const COURSES_PER_PAGE = 5;
    const { token } = useRecoilValue(userState);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState([]);
    const [courses, setCourses] = useState([]);
    const [showToast, setShowToast] = useOutletContext();
    const navigate = useNavigate();

    const allPlannedCourses = useQuery({
        queryKey: ['allplannedcourses'],
        queryFn: () => getRequest(`/auth/course/planned?page=${page}&amount=${COURSES_PER_PAGE}`, token)
    });
    const { data } = allPlannedCourses;

    useEffect(() => {
        if (data) {
            const pagesAmount = Math.ceil(data.total / COURSES_PER_PAGE);
            setTotalPages(pagesAmount);
            setCourses(data.courses);
        }
    }, [data]);

    useEffect(() => {
        allPlannedCourses.refetch();
    }, [page]);

    return (
        <Loader isActive={allPlannedCourses.isFetching || allPlannedCourses.isLoading}>
            {data &&
                <>
                    <Container fluid>
                        <Row className="border-0 p-3 bg-dark-subtle border-bottom border-white mb-3">
                            <Col xs={2} className="p-0">
                                <ArrowLeft
                                    size={24}
                                    onClick={() => navigate(-1)}
                                    style={{cursor: 'pointer'}}
                                />
                            </Col>
                            <Col 
                                xs={8}
                                className="text-center d-flex align-items-center justify-content-center"
                            >
                                <h5 className="m-0">Alle geplanten Kurse</h5>
                            </Col>
                            <Col xs={2}>
                                
                            </Col>
                        </Row>

                        <Container>
                            {data.courses.length < 1 
                            ? 
                                <Row className="mt-3">
                                    <Col className="text-center">
                                        <p>Derzeit sind keine weiteren Kurse geplant.</p>
                                        <Button onClick={() => navigate('/new-course')}>Neuen Kurs planen</Button>
                                    </Col>
                                </Row>
                            :
                                <>
                                    <Row className="mt-3">
                                        {data.courses.map((course) => {
                                            return (
                                                <Card 
                                                    className="mt-3 mb-3 p-0"
                                                    key={course.id}
                                                >
                                                    <Card.Header className="p-3">
                                                        <h5 className="m-0">{course.title}</h5>
                                                    </Card.Header>
                                                    <Card.Body className="p-0">
                                                        <Row className="m-0 p-3 border-bottom">
                                                            <Col className="p-0" xs={3}>Datum:</Col>
                                                            <Col className="text-end" xs={9}>{formatDateTimeToDDMMYY(course.date)}</Col>
                                                        </Row>
                                                        <Row className="m-0 p-3 border-bottom">
                                                            <Col className="p-0" xs={3}>Uhrzeit:</Col>
                                                            <Col className="text-end" xs={9}>{formatDateTimeToHHMM(course.date)} Uhr</Col>
                                                        </Row>
                                                        <Row className="m-0 p-3 border-bottom">
                                                            <Col className="p-0" xs={3}>Ort:</Col>
                                                            <Col className="text-end" xs={9}>
                                                                {course.locationName}, {course.locationCity}
                                                            </Col>
                                                        </Row>
                                                        <Row className="m-0 p-3">
                                                            <Col className="p-0" xs={9}>Maximale Teilnehmer:</Col>
                                                            <Col className="text-end" xs={3}>{course.maxParticipants}</Col>
                                                        </Row>
                                                    </Card.Body>
                                                    <Card.Footer className="p-3">
                                                        <Row>
                                                            <Col xs={6}>
                                                                <Button 
                                                                    className="bg-success border-0 w-100"
                                                                    onClick={async () => {
                                                                        await patchRequest('/auth/course/activate', token, {
                                                                            id: course.id
                                                                        });
                                                                        allPlannedCourses.refetch();
                                                                        setShowToast({
                                                                            toastStatus: true,
                                                                            toastMessage: 'Kurs erfolgreich freigegeben. 🥳',
                                                                            toastAppearance: 'success'
                                                                        });
                                                                    }}
                                                                >
                                                                    Kurs freigeben
                                                                </Button>
                                                            </Col>
                                                            <Col xs={6}>
                                                                <Button 
                                                                    className="bg-primary border-0 w-100"
                                                                    onClick={() => navigate(`/edit-course/${course.id}`)}
                                                                >
                                                                    Kurs bearbeiten
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Card.Footer>
                                                </Card>
                                            )
                                        })}
                                    </Row>
                                    {data.courses.length > 0 &&
                                        <Row className="mt-3">
                                            <Col xs={4} className="text-start">
                                                {page > 1 &&
                                                    <ChevronDoubleLeft
                                                        onClick={() => setPage((prev) => {return prev - 1})}
                                                    />
                                                }
                                            </Col>
                                            <Col xs={4} className="text-center">
                                                <p>{`Seite ${page} von ${totalPages}`}</p>
                                            </Col>
                                            <Col xs={4} className="text-end">
                                                {totalPages > page &&
                                                    <ChevronDoubleRight 
                                                        onClick={() => setPage((prev) => {return prev + 1})}
                                                    />
                                                } 
                                            </Col>
                                        </Row>
                                    }
                                </>
                            }
                        </Container>
                    </Container>
                </>
            }
        </Loader>
    )
}