import { Button, Col, Container, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom"

export const RegisterFinish = () => {

    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/');
    }

    return (
        <Container className="min-vh-100 d-flex flex-column align-items-center justify-content-center p-5">
            <Row>
                <Col className="text-center">
                    <h1 className="mb-3">🥳</h1>
                    <h2 className="mb-3">Vielen Dank für deine Registrierung</h2>
                    <p className="mb-3">
                        Deine Registrierung wurde erfolgreich abgeschlossen. Sobald dein Account freigeschaltet wurde,
                        erhätst du eine Bestätigung per E-Mail. Dies dauert in der Regel nicht länger als 24 Stunden.
                        Bei weiteren Fragen kontaktiere mich gerne unter info@kiddiefy.de.
                    </p>
                    <Button
                        onClick={handleClick}
                    >
                        Zurück zum Start
                    </Button>
                </Col>
            </Row>
        </Container>
    )
}