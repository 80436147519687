import { Button, Modal } from "react-bootstrap"
import { postRequest } from "../../../../../utils/apiRequest"
import { useOutletContext } from "react-router-dom";

export const ChildDetailRemoveBadgeConfirmModal = ({showDeleteConfirmModal, setShowDeleteConfirmModal, badgeToDeleteId, token, refetch }) => {

    const [showToast, setShowToast] = useOutletContext();

    const handleDeleteBadge = async () => {
        const response = await postRequest('/auth/remove-badge', token, {id: badgeToDeleteId});
        if (response.error) {
            setShowToast({
                toastStatus: true, 
                toastMessage: response.error,
                toastAppearance: 'danger'
            });
        }
        else {
            setShowToast({
                toastStatus: true, 
                toastMessage: 'Abzeichen erfolgreich entfernt. 🥳',
                toastAppearance: 'success'
            });
        }
        refetch();
        setShowDeleteConfirmModal(false);
    }

    return (
        <Modal centered show={showDeleteConfirmModal} onHide={() => setShowDeleteConfirmModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Abzeichen entfernen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Möchtest du das Abzeichen wirklich entfernen?
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    className="btn-danger"
                    onClick={() => setShowDeleteConfirmModal(false)}
                    >
                        Abbrechen
                    </Button>
                <Button
                    onClick={handleDeleteBadge}
                >
                    Abzeichen entfernen
                </Button>
            </Modal.Footer>
        </Modal>
    )
}