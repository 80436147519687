import { json } from "react-router-dom";

export const getRequest = async (endpoint, token) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json' 
            }
        });
        const jsonResponse = await response.json();
        return jsonResponse;
    }
    catch (error) {
        return ({'error': 'Verbindung zum Server konnte nicht hergestellt werden.'});
    }
}

export const postRequest = async (endpoint, token, data = {}) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        const jsonResponse = await response.json();
        return jsonResponse;
    }
    catch (error) {
        console.log(error);
        return ({'error': 'Verbindung zum Server konnte nicht hergestellt werden.'});
    }
}

export const patchRequest = async (endpoint, token, data = {}) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
            method: 'PATCH',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        const jsonResponse = await response.json();
        return jsonResponse;
    }
    catch (error) {
        console.log(error);
        return ({'error': 'Verbindung zum Server konnte nicht hergestellt werden.'});
    }
}



