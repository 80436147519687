import { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { postRequest } from '../utils/apiRequest';
import { sha256 } from 'js-sha256';
import { useSetRecoilState } from 'recoil';
import { userState } from '../utils/atom';
import { getLocalStorage, setLocalStorage } from '../utils/localStorage';
import mainLogo from '../assets/logo/logo-white.png';

export const Login = () => {

    const setUserState = useSetRecoilState(userState);
    const [isStayLoggedIn, setIsStayLoggedIn] = useState(false);
    const [error, setError] = useState('');
    const [loginData, setLoginData] = useState({
        email: '',
        password: ''
    });
    
    useEffect(() => {
        const checkAutoLogin = async () => {
            const token = getLocalStorage('token');
            if (token) {
                const response = await postRequest('/auth/verify', token);
                if (response.token) {
                    setLocalStorage('token', response.token);
                    setUserState(response);
                }
            }
        }
        checkAutoLogin();
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLoginData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await postRequest('/login', '', {
            email: loginData.email,
            password: sha256(loginData.password)
        });
        if (response.error) {
            setError(response.error);
            return;
        }
        if (isStayLoggedIn) {
            setLocalStorage('token', response.token);
        }
        setUserState(response);
    }

    return (
        <>
            <Container className='min-vh-100 d-flex flex-column align-items-center justify-content-center'>
                <Row>
                    <Col xs={12}>
                        <Image 
                            width={200}
                            src={mainLogo} 
                        />
                    </Col>
                </Row>

                {/* <Row className='w-100'>
                    <Col className='mb-3'>
                        <h1 className='text-center'>Kiddiefy Kursverwaltung</h1>
                    </Col>
                </Row> */}

                <Row className='w-100 justify-content-center'>
                    <Col xs={12} md={6}>
                        <Form className='p-3' onSubmit={handleSubmit}>
                            <Form.Group className='mb-3'>
                                <Form.Control 
                                    className='text-center' 
                                    type='email' 
                                    placeholder='E-Mail-Adresse'
                                    name='email'
                                    value={loginData.email}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group className='mb-3'>
                                <Form.Control 
                                    className='text-center' 
                                    type='password' 
                                    placeholder='Passwort'
                                    name='password'
                                    value={loginData.password}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group className='d-flex justify-content-between mb-3'>
                                <Form.Label>Angemeldet bleiben?</Form.Label>
                                <Form.Check
                                    type='switch'
                                    checked={isStayLoggedIn}
                                    onChange={(e) => setIsStayLoggedIn(e.target.checked)}
                                />
                            </Form.Group>
                            

                            <Form.Group className='text-center'>
                                <Button type='submit' variant='primary' className='w-100'>
                                    Login
                                </Button>
                            </Form.Group>
                        </Form>
                        {error &&
                            <p className='text-center text-danger'>{error}</p>
                        }
                    </Col>
                </Row>

                <Row className='w-100'>
                    <Col>
                        <p className='text-center'>
                            Sie haben noch keinen Kiddiefy-Account?<br /><Link to={'/register'} >Jetzt registrieren!</Link>
                        </p>
                    </Col>
                </Row>

                <Row className='w-100'>
                    <Col>
                        <p className='text-center'>
                            <Link to={'reset-password'}>Passwort vergessen</Link>
                        </p>
                    </Col>
                </Row>
            </Container>
        </>
    )
}