import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap"
import { Loader } from "../../../../components/loader"
import { LockFill, Phone, PhoneFill, SendFill, UnlockFill } from "react-bootstrap-icons"
import { useState } from "react"
import { useOutletContext } from "react-router-dom"
import { postRequest } from "../../../../utils/apiRequest"

export const UserContact = ({user, token}) => {

    const [userData, setUserData] = useState(user);
    const [isEditable, setIsEditable] = useState(false);
    const [showToast, setShowToast] = useOutletContext();
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (event) => {
        const {name, value} = event.target;
        setUserData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        const data = {
            id: userData.id,
            email: userData.email,
            phone: userData.phone
        };
        const response = await postRequest('/auth/update-user', token, data);
        if (response.error) {
            setShowToast({
                toastStatus: true, 
                toastMessage: response.error,
                toastAppearance: 'danger'
            });
        }
        else {
            setShowToast({
                toastStatus: true, 
                toastMessage: 'Nutzerdaten aktualisiert. 🥳',
                toastAppearance: 'success'
            });
            setIsEditable((prev) => !prev);
        }
        setIsLoading(false);
    }

    return (
        <Loader isActive={false}>
            <Card className="mt-3">
                <Card.Header>
                    <Row>
                        <Col xs={6}>
                            Kontakt
                        </Col>
                        <Col xs={6} className="text-end">
                            {!isEditable 
                                ? <LockFill onClick={() => setIsEditable((prev) => !prev)} style={{cursor: 'pointer'}} /> 
                                : <UnlockFill onClick={() => setIsEditable((prev) => !prev)} style={{cursor: 'pointer'}} />
                            }
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col xs={12}>
                            <Form.Label>E-Mail</Form.Label>
                            <InputGroup >
                                <Form.Control
                                    type="text"
                                    value={userData.email}
                                    disabled={!isEditable}
                                    name="email"
                                    onChange={handleChange}
                                >
                                </Form.Control>
                                <Button
                                    className="w-10"
                                    onClick={() => window.location.href = `mailto:${user.email}`}
                                > 
                                    <SendFill></SendFill>
                                </Button>
                            </InputGroup>
                        </Col>
                    </Row>

                    <Row className="mt-3">
                        <Col xs={12}>
                            <Form.Label>Telefon</Form.Label>
                            <InputGroup >
                                <Form.Control
                                    type="text"
                                    value={userData.phone}
                                    disabled={!isEditable}
                                    name="phone"
                                    onChange={handleChange}
                                >
                                </Form.Control>
                                <Button
                                    className="w-10"
                                    onClick={() => window.location.href = `tel:${user.phone}`}
                                > 
                                    <PhoneFill></PhoneFill>
                                </Button>
                            </InputGroup>
                        </Col>
                    </Row>
                </Card.Body>
                <Card.Footer className="p-3">
                    <Button
                        disabled={!isEditable}
                        onClick={handleSubmit}
                    >
                        Änderungen speichern
                    </Button>
                </Card.Footer>
            </Card>
        </Loader>
    )
}