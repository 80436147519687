import { useQuery } from "@tanstack/react-query";
import { Button, Card, Col, Row } from "react-bootstrap"
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { getRequest, postRequest } from "../../../../utils/apiRequest";
import { Loader } from "../../../../components/loader";
import { useEffect, useState } from "react";
import { BankDetailModal } from "../../../../components/modals/bankDetailModal";

export const UserUnpaidEnrollment = ({user, token}) => {

    const { id } = useParams();
    const navigate = useNavigate();
    const [totalCost, setTotalCost] = useState(0);
    const [showBankModal, setShowBankModal] = useState(false);
    const [showToast, setShowToast] = useOutletContext();

    const unpaidEnrollmentQuery = useQuery({
        queryKey: ["unpaid-enrollments-by-id"],
        queryFn: () => getRequest(`/auth/unpaid-enrollments-by-id/${id ? id : user.id}`, token),
    });
    const { data } = unpaidEnrollmentQuery;

    useEffect(() => {
        if (data) {
            const totalCostCalculated = data.reduce((acc, enrollment) => {
                return acc + Number(enrollment.cost);
            }, 0);
            setTotalCost(totalCostCalculated);
        }
    }, [data]);

    const handlePaypal = (total) => {
        window.open(`https://paypal.me/kiddiefyallaboutkids/${total}`)
    }

    const handlePaymentReminderButton = async (id) => {
        const response = await postRequest('/auth/payment-reminder', token, {id: id});
        if (response.error) {
            setShowToast({
                toastStatus: true,
                toastMessage: response.error,
                toastAppearance: 'danger'
            })
        }
        else {
            setShowToast({
                toastStatus: true,
                toastMessage: 'Zahlungserinnerung erfolgreich verschickt. 😊',
                toastAppearance: 'success'
            })
        }
    }

    return (
        <Loader isActive={unpaidEnrollmentQuery.isLoading || unpaidEnrollmentQuery.isFetching}>
            {data &&
                <Card className="mt-3">
                    <Card.Header>Offene Rechnungen</Card.Header>
                    <Card.Body className="pt-0 pb-0 no-border-on-last-child ">
                        {data.length < 1 
                            ?
                                <p className="m-0 pt-3 pb-3">Keine offenen Kursrechnungen.</p>
                            :
                                <>
                                    {data.map((enrollment) => {
                                        return (
                                            <Row
                                                key={enrollment.id}
                                                className="hoverable-title text-danger pt-3 pb-3 border-bottom"
                                                onClick={() => navigate(`/course/${enrollment.course.id}`)}
                                            >
                                                <Col  xs={4}>
                                                    {enrollment.child.firstName}
                                                </Col>

                                                <Col  xs={4} className="text-center">
                                                    {enrollment.course.title}
                                                </Col>

                                                <Col xs={4} className="text-end">
                                                    {enrollment.cost} €
                                                </Col>
                                            </Row>
                                        )
                                    })}
                                    <Row className="pt-3 pb-3">
                                        <Col xs={9}>Offene Gesamtrechnung:</Col>
                                        <Col xs={3} className="text-end">{totalCost} €</Col>
                                    </Row>
                                </>
                        }
                    </Card.Body>
                    <Card.Footer className="p-3">
                        {!id &&
                            <Row>
                                <Col xs={6}>
                                    <Button onClick={() => handlePaypal(totalCost)} className="w-100">Paypal</Button>
                                </Col>
                                <Col xs={6}>
                                    <Button 
                                        className="w-100"
                                        onClick={() => setShowBankModal(true)}
                                    >
                                        Überweisung
                                    </Button>
                                </Col>
                            </Row>
                        }
                        {id &&
                            <Row>
                                <Col xs={12}>
                                    <Button
                                        onClick={() => handlePaymentReminderButton(user.id)}
                                    >
                                        Zahlungserinnerung senden
                                    </Button>
                                </Col>
                            </Row>
                        }
                    </Card.Footer>
                    <BankDetailModal 
                        show={showBankModal}
                        hide={() => setShowBankModal(false)}
                        user={user}
                    />
                </Card>         
            }
        </Loader>
    )
}