import { useEffect, useState } from "react"
import { Loader } from "../../../components/loader";
import { useRecoilValue } from "recoil";
import { userState } from "../../../utils/atom";
import { useQuery } from "@tanstack/react-query";
import { getRequest, postRequest } from "../../../utils/apiRequest";
import { Accordion, Button, Card, Col, Container, Form, FormControl, InputGroup, Row, Table } from "react-bootstrap";
import { ArrowBarLeft, ArrowLeft, ChevronDoubleLeft, ChevronDoubleRight, Search } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import '../../../styles/accordion/accordion.scss';

export const AllUsers = () => {

    const [USERS_PER_PAGE, SET_USERS_PER_PAGE] = useState(25);
    const { token } = useRecoilValue(userState);
    const [users, setUsers] = useState([]);
    const [amount, setAmount] = useState(1);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchValues, setSearchValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        childFirstName: ''
    });
    const navigate = useNavigate();

    const allUsers = useQuery({
        queryKey: ['all-users'],
        queryFn: () => getRequest(`/auth/all-users?page=${page}&amount=${USERS_PER_PAGE}`, token)
    });
    let { data } = allUsers;
    
    useEffect(() => {
        if (data) {
            setUsers(data.users);
            setAmount(data.amount);
        }
    }, [data]);

    useEffect(() => {
            const pagesAmount = Math.ceil(amount / USERS_PER_PAGE);
            setTotalPages(pagesAmount);
    }, [amount]);

    useEffect(() => {
        allUsers.refetch();
    }, [page])

    const handleSearchChange = (event) => {
        const { name, value } = event.target;
        setSearchValues(prevState => ({
            ...prevState,
            [name]: value
        })); 
    }

    const handleSearchButton = async () => {
        const data = searchValues;
        const response = await postRequest('/auth/find-user', token, data);
        setUsers(response.user);
        setAmount(response.amount);
        setPage(1);
    }

    return (
        <Loader isActive={allUsers.isFetching || allUsers.isLoading}>
            {data &&
                <Container fluid>
                    <Row className="border-0 p-3 bg-dark-subtle border-bottom border-white mb-3">
                        <Col xs={2} className="p-0">
                            <ArrowLeft 
                                size={24}
                                onClick={() => navigate('/')}
                                style={{cursor: 'pointer'}}
                            />
                        </Col>
                        <Col 
                            xs={8}
                            className="text-center d-flex align-items-center justify-content-center"
                        >
                            <h5 className="m-0">Alle Benutzer</h5>
                        </Col>
                        <Col xs={2}>
                            
                        </Col>
                    </Row>

                    <Row className="mb-3 mt-3">
                        <Col xs={12}>     
                            <Accordion>
                                <Accordion.Item eventKey={1}>
                                    <Accordion.Header>
                                        Benutzer suchen
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <Row className="mb-3">
                                            <Col xs={6}>
                                                <FormControl
                                                    type="text"
                                                    placeholder="Vorname"
                                                    value={searchValues.firstName}
                                                    name="firstName"
                                                    onChange={handleSearchChange}
                                                >
                                                </FormControl>
                                            </Col>

                                            <Col xs={6}>
                                                <FormControl
                                                    type="text"
                                                    placeholder="Nachname"
                                                    value={searchValues.lastName}
                                                    name="lastName"
                                                    onChange={handleSearchChange}
                                                >
                                                </FormControl>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={12} md={6} className="mb-3">
                                                <FormControl
                                                    type="email"
                                                    placeholder="E-Mail"
                                                    value={searchValues.email}
                                                    name="email"
                                                    onChange={handleSearchChange}
                                                >
                                                </FormControl>
                                            </Col>
                                            <Col xs={12} md={6} className="mb-3">
                                                <FormControl
                                                    type="text"
                                                    placeholder="Telefon"
                                                    value={searchValues.phone}
                                                    name="phone"
                                                    onChange={handleSearchChange}
                                                >
                                                </FormControl>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={12} md={6} className="mb-3">
                                                <FormControl
                                                    type="text"
                                                    placeholder="Vorname des Kindes"
                                                    value={searchValues.childFirstName}
                                                    name="childFirstName"
                                                    onChange={handleSearchChange}
                                                >
                                                </FormControl>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col className="text-center">
                                                <Button 
                                                    className="w-100"
                                                    onClick={handleSearchButton}
                                                    >
                                                        Suchen
                                                </Button>
                                            </Col>
                                        </Row>
                                        
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="text-center mb-3">
                            {amount} aktive Benutzer in der Datenbank
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                        {users.length < 1
                    ?
                        <p>Keine Benutzer vorhanden.</p>
                        
                    :
                        //TODO: Make table sortable
                        <Table responsive hover bordered>
                            <thead>
                                <tr>
                                    <th className="p-3">Vorname</th>
                                    <th className="p-3">Nachname</th>
                                    <th className="p-3">E-Mail</th>
                                    <th className="p-3">Telefon</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((user) => {
                                    return (
                                        <tr 
                                            key={user.id} 
                                            style={{cursor: 'pointer'}}
                                            onClick={() => navigate(`/user/${user.id}`)}
                                        >
                                            <th className="p-3">{`${user.firstName}`}</th>
                                            <th className="p-3">{`${user.lastName}`}</th>
                                            <th className="p-3">{`${user.email}`}</th>
                                            <th className="p-3">{`${user.phone}`}</th>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    }
                        </Col>
                    </Row>
                    {amount > 0 &&                     
                        <Row>
                            <Col xs={2} className="d-flex align-items-center">
                                {page > 1 &&
                                    <ChevronDoubleLeft
                                        style={{cursor: 'pointer'}}
                                        onClick={() => setPage((prev) => {return prev - 1})}
                                    />
                                }
                            </Col>
                            <Col xs={8} className="mb-3 mt-3">
                                <Form.Select
                                    value={page}
                                    onChange={(e) => setPage(parseInt(e.target.value))}
                                >  
                                    {Array.from({ length: totalPages }, (_, index) => (
                                        <option className="text-center" key={index + 1} value={index + 1}>
                                            {`Seite ${index + 1} von ${totalPages}`}
                                        </option>
                                    ))}                                  
                                </Form.Select>
                            </Col>
                            <Col xs={2} className="d-flex align-items-center justify-content-end">
                                {page < totalPages &&
                                    <ChevronDoubleRight 
                                        style={{cursor: 'pointer'}}
                                        onClick={() => setPage((prev) => {return prev + 1})}
                                    />
                                } 
                            </Col>
                        </Row>     
                    }
                </Container> 
                
            }
        </Loader>
    )
}