import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userState } from "../../../utils/atom";
import { postRequest } from "../../../utils/apiRequest";
import { useQuery } from "@tanstack/react-query";
import { Loader } from "../../../components/loader";
import { Col, Container, Row, Stack } from "react-bootstrap";
import { TitleWithBackButton } from "../../../components/title-with-back-button/titleWithBackButton";
import { ChildDetailBadgesUser } from "./child-detail-components/childDetailBadgesUser";
import { ChildDetailGeneralUser } from "./child-detail-components/childDetailGeneralUser";
import { ChildDetailPerformanceUser } from "./child-detail-components/childDetailPerformanceUser";

export const ChildDetailUser = () => {

    const { id } = useParams(); 
    const { token } = useRecoilValue(userState); 

    const childQuery = useQuery({
        queryKey: ["child-by-id-user"],
        queryFn: () => postRequest('/auth/get-child', token, {id: id}),
    });
    const { data: child } = childQuery;

    return (
        <Loader isActive={childQuery.isLoading || childQuery.isFetching}>
            {child &&
                <Container fluid>
                    <TitleWithBackButton title={`${child.firstName} ${child.lastName}`}/>
                    
                    <Container>
                        <Row>
                            <Col xs={12} md={6}>
                                <Stack>
                                    <ChildDetailGeneralUser 
                                        child={child} 
                                        token={token}
                                    />
                                </Stack>
                            </Col>

                            <Col xs={12} md={6}>
                                <Stack>
                                    <ChildDetailPerformanceUser child={child} />
                                    <ChildDetailBadgesUser child={child} />
                                </Stack>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            }
        </Loader>
    )
}