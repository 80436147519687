import { useRecoilValue } from "recoil";
import { userState } from "../../utils/atom";
import { useQuery } from "@tanstack/react-query";
import { Loader } from '../loader';
import { Alert } from "react-bootstrap";
import { getRequest } from "../../utils/apiRequest";
import { useNavigate } from "react-router-dom";

export const News = ({ isEditable }) => {

    const { token } = useRecoilValue(userState);
    const navigate = useNavigate();
    const newsQuery = useQuery({ 
        queryKey: ['news-query'],
        queryFn: () => getRequest('/auth/get-news', token),
    }
    );
    const { data } = newsQuery;

    return (
        <Loader isActive={newsQuery.isFetching || newsQuery.isLoading}>
            {data &&
                <>
                    {data.length > 0 && data[0].isActive
                        ?
                            <Alert 
                                variant={data[0].color}
                                style={isEditable ? {cursor: 'pointer'} : {}}
                                onClick={() => isEditable && navigate('/new-news')}
                            >
                                <Alert.Heading>{data[0].title}</Alert.Heading>
                                <p className="m-0">{data[0].text}</p>
                            </Alert>
                        :
                            <></>
                    }
                </>
            }
        </Loader>
    )
}